<template>
    <v-row class="align-center d-flex h-100 mt-md-n16">
        <v-col lg="4" md="7" class="mx-auto">
            <v-card class="card-shadow border-radius-xl py-1">
                <div class="
            card-padding
            text-center
            mt-n7
            bg-gradient-primary
            shadow-primary
            border-radius-lg
            mx-4
          ">
                    <h4 class="text-h4 font-weight-bolder text-white mb-2">
                        重置密碼
                    </h4>
                    <p class="mb-1 text-white font-weight-light text-sm">
                        輸入您的電子郵件重置密碼
                    </p>
                </div>
                <div class="text-center">
                    <v-progress-circular indeterminate :size="50" :width="5" color="blue" v-if="isShowProgress"></v-progress-circular>
                    <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                    <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                </div>
                <div class="card-padding text-center">
                    <v-text-field v-model="email" @keydown.enter="resetPassword" label="電子郵件" color="#e91e63" outlined dense hide-details class="font-size-input input-style mb-5"></v-text-field>
                    <v-btn elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            " color="#5e72e4" small @click="resetPassword">送出</v-btn>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

export default {
    name: "sign-up-basic",
    data() {
        return {
            checkbox: false,
            email: "",
            alertSuccess: false,
            alertError: false,
            timeout: 2000,
            repsMessage: "",

        };
    },
    methods: {
        resetPassword() {
            //this.$swal("", "已傳送密碼重置信至電子郵件", "success");

            let resp = axios.post('/v1/reset/password', {
                    email: this.email,

                }, this.config)
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)

                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.error_code
                    console.log(this.repsMessage)
                    console.log(errorCode)

                    if (errorCode === '') {
                        this.alertSuccess = true

                        setTimeout(() => {
                            this.$router.push('/pages/authentication/signup/login');
                        }, this.timeout)

                    } else {
                        this.alertError = true
                    }

                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });

        },

    },
};
</script>