<template>
    <v-row class="align-center d-flex h-100 mt-md-n16">
        <v-col lg="4" md="7" class="mx-auto">
            <v-card class="card-shadow border-radius-xl py-1">
                <div class="
            card-padding
            text-center
            mt-n7
            bg-gradient-primary
            shadow-primary
            border-radius-lg
            mx-4
          ">
                    <h4 class="text-h4 font-weight-bolder text-white mb-2">
                        驗證狀態
                    </h4>
                </div>
                <div class="card-padding text-center">
                    <p v-if="verifying" class="mb-1 font-weight-light text-sm">
                        驗證中...
                    </p>
                    <p v-if="verifySuccess" class="mb-1 font-weight-light text-sm">
                        驗證成功
                    </p>
                    <p v-if="verifyFailed" class="mb-1 font-weight-light text-sm">
                        驗證失敗
                    </p>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Base64 = require("js-base64").Base64;

export default {
    name: "sign-up-basic",
    data() {
        return {
            checkbox: false,
            timeout: 3000,
            verifying: true,
            verifySuccess: false,
            verifyFailed: false,

        };
    },
    mounted() {
        let user_id = this.$route.query.user_id
        let active_code = this.$route.query.active_code

        let data = {
            'user_id': user_id,
            'active_code': active_code,
        };

        const resp = axios.get('/v1/verify/phone', {
                params: data
            }, this.config)
            .then(response => {
                console.log(response)
                this.repsMessage = response.data.message
                const errorCode = response.data.error_code
                console.log(this.repsMessage)
                console.log(errorCode)

                if (errorCode === '') {
                    this.verifySuccess = true
                    this.verifyFailed = false
                    this.verifying = false

                } else {
                    this.verifyFailed = true
                    this.verifySuccess = false
                    this.verifying = false
                }

            })
            .catch(function(error) { // 请求失败处理
                console.log(error);
            });

        //setTimeout(() => {
        //    this.$router.push('/pages/pages/profile/overview');
        //}, this.timeout)


        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof token)
        console.log((token === 'null') || (typeof (token) === "undefined") || (token === ""))
        if ((token === 'null') || (typeof (token) === "undefined") || (token === "")) {
            setTimeout(() => {
                this.$router.push('/login');
            }, this.timeout)
        } else {
            setTimeout(() => {
                this.$router.push('/profile');
            }, this.timeout)
        }




    },
};
</script>