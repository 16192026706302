<template>
  <v-container class="py-3 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/verify-phone.jpg')}) no-repeat center center fixed; background-size: cover; height: 100vh;`">

      <v-row class="text-center mx-10">
        <v-col cols="12" class="pt-6 pb-0">
          <div class="bg-primary opacity-0" style="height: 80px; width: 60px;"></div>
        </v-col>
        <v-col cols="12">

          <v-text-field v-model="phone" type="tel" placeholder="+886  987 654 321" solo
            class="rounded-pill custom-placeholer-color" style="height: 50px; font-size: 18px">
          </v-text-field>

        </v-col>
        <v-col cols="12" class="pt-0">

          <v-btn color="#1A237E" class="
            btn-default
            rounded-pill
          " style="width:100%;height: 50px; font-size: 18px;" @click="sendPhoneVerifyCode">
            獲取驗證碼
          </v-btn>

        </v-col>
        <v-col cols="12">
          <p class="text-white" style="font-size: 10px;">驗證即代表同意 用戶協議 和 隱私政策</p>
        </v-col>

      </v-row>
    </div>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-progress-circular v-if="isShowProgress" indeterminate color="#5E35B1" :size="70" :width="7"
          class="progress-overlay"></v-progress-circular>
      </v-row>
    </v-container>

  </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';
import { userDataUpdate, userData } from "@/apis/userService";

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": "application/json;",
  }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

export default {
  name: "SettingsPhone",
  data() {
    return {
      userId: 1,
      switche: true,
      name: "",
      role: "",
      line_picture_link: "",
      phone: "",
      isShowProgress: false,

    };
  },
  mounted() {
    const token = Cookie.get('auth')

    console.log(token)
    console.log(typeof token)
    console.log((token === 'null') || (typeof (token) === "undefined"))
    if ((token === 'null') || (typeof (token) === "undefined")) {
      this.$router.push('/login');
    }

    const tokenParts = token.split('.')
    console.log(tokenParts)
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    userData(this.userId)
      .then(response => {
        this.repsData = response.data;
        console.log(this.repsData)

        this.name = this.repsData.name
        this.address = this.repsData.address
        this.gender = this.repsData.gender === 2 ? "女性" : "男性" //this.genders[this.repsData.gender - 1]
        this.phone = this.repsData.phone
        this.email = this.repsData.email
        this.email_pass_status = this.repsData.email_pass_status
        this.phone_pass_status = this.repsData.phone_pass_status
        this.role = this.repsData.role
        this.due_date = this.repsData.due_date
        this.birthday = this.repsData.birthday
        this.bank_code = this.repsData.bank_code
        this.bank_account = this.repsData.bank_account
        this.identity = this.repsData.identity
        this.line_notify_token = this.repsData.line_notify_token


      })
      .catch(error => { // 请求失败处理
        console.log(error);
      });
  },
  methods: {
    sendPhoneVerifyCode() {
      // 檢查有效行動電話號碼
      if (this.phone.length < 10 || this.phone.length > 13) {
        this.$swal("", "請輸入有效行動電話號碼，範例：0987654321 或 +886987654321", "error");
        return;
      }

      userDataUpdate({
        name: this.name,
        gender: this.gender,
        phone: this.phone,
        email: this.email,
        role: this.role,
        due_date: this.due_date,
        birthday: this.birthday,
        address: this.address,
        bank_code: this.bank_code,
        bank_account: this.bank_account,
        identity: this.identity,
        password: "",
        code: "",
      }, this.userId)
        .then(response => {
          console.log(response)
          this.repsMessage = response.data.message
          const errorCode = response.data.code
          console.log(this.repsMessage)
          console.log(errorCode)

          let resp = axios.post('/v1/verify/phone/' + this.userId.toString(), config)
            .then(response => {
              this.repsData = response.data;
              console.log(this.repsData)
              const errorCode = response.data.error_code
              const message = response.data.message

              if (errorCode === '') {
                this.isShowProgress = true
                setTimeout(() => {
                  this.isShowProgress = false
                  this.$router.push('/profile-settings/verify-phone');
                }, 1000)


              } else {
                console.log(message)
                this.$swal("", message, "error");
              }

            })
            .catch(error => { // 请求失败处理
              console.log(error);
            });

        })
        .catch(function (error) { // 请求失败处理
          console.log(error);
        });



    },
  },
};
</script>
<style scoped>
::v-deep .custom-placeholer-color input::placeholder {
  color: #9FA8DA !important;
  opacity: 1;
}
</style>
<style scoped>
/* 使進度條在螢幕上居中，並且至於最頂層 */
.progress-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>