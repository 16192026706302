<template>
    <v-container fluid class="py-6">
        <v-row>
            <v-col md="8" sm="10" class="mx-auto">
                <v-card>
                    <v-row md="8" sm="10" class="mx-auto">
                        <v-col cols="2">
                            <v-select v-model="year" :items="years" color="#e91e63" class="font-size-input input-style" @change="changeYears" single-line>
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select v-model="month" :items="months" color="#e91e63" class="font-size-input input-style" @change="changeMonths" single-line>
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-btn @click="searchRangeGroup" :ripple="false" class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    ">確認</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card>
                    <v-layout justify-space-between pa-3>
                        <v-flex>
                            <v-treeview open-all :active.sync="active" :items="items" :open.sync="open" activatable active-class="primary--text" class="grey lighten-5" transition>
                                <!--<template v-slot:prepend="{ item, active }">
                                    <v-icon v-if="!item.children" :color="active ? 'primary' : ''">
                                        mdi-account
                                    </v-icon>
                                </template>-->
                                <template v-slot:label="{ item }">
                                   <v-icon v-if="!item.roleStatus" color="#6495ED" class="mt-n1 ps-1 pe-2">fa-user</v-icon>
                                    <v-icon v-if="item.roleStatus" color="#FFBF00" class="mt-n1 ps-1 pe-2">fa-users</v-icon>&nbsp;<u class="v-icon v-icon--link">{{ item.name }}</u>&nbsp;<v-icon v-if="item.isPass" color="#5FAF58" class="mt-n1 ps-1 pe-2">fa-check-circle</v-icon>
                                    <v-icon v-if="!item.isPass" color="#FF3333" class="mt-n1 ps-1 pe-2">fa-times-circle</v-icon>
                                </template>
                            </v-treeview>
                        </v-flex>
                        <v-flex d-flex text-xs-center>
                            <v-scroll-y-transition mode="out-in">
                                <div v-if="!selected" class="title grey--text text--lighten-1 font-weight-light" style="align-self: center;">
                                    選擇一個經銷商
                                </div>
                                <v-card v-else :key="selected.id" class="pt-4 mx-auto" flat max-width="400">
                                    <v-card-text>
                                        <!--<v-avatar v-if="avatar" size="88">
                                            <v-img :src="`https://avataaars.io/${avatar}`" class="mb-4"></v-img>
                                        </v-avatar>-->
                                        <h3 class="headline mb-2">
                                            {{ selected.name }} &nbsp;
                                            <v-icon v-if="selected.isPass" color="#5FAF58" class="mt-n1 ps-1 pe-2">fa-check-circle</v-icon>
                                            <v-icon v-if="!selected.isPass" color="#FF3333" class="mt-n1 ps-1 pe-2">fa-times-circle</v-icon>
                                        </h3>
                                        <div class="blue--text mb-2">{{ selected.level }}階</div>
                                        <div class="blue--text mb-2">{{ selected.phone }}</div>
                                        <div class="blue--text mb-2">{{ selected.email }}</div>
                                    </v-card-text>
                                    <!--<v-divider></v-divider>
                                    <v-layout tag="v-card-text" text-xs-left wrap>-->
                                        <!--<v-flex tag="strong" xs6 text-xs-right mr-3 mb-2>行動電話：{{ selected.phone }}</v-flex>-->
                                        <!--<v-flex tag="strong" xs6 text-xs-right mr-3 mb-2>當月積分：{{ selected.pointsByMonth }}</v-flex>-->
                                        <!--<v-flex tag="strong" xs6 text-xs-right mr-3 mb-2>年度積分：{{ selected.pointsByYear }}</v-flex>-->
                                        <!--<v-flex tag="strong" xs5 text-xs-right mr-3 mb-2>Company:</v-flex>
                                        <v-flex>{{ selected.company.name }}</v-flex>
                                        <v-flex tag="strong" xs5 text-xs-right mr-3 mb-2>Website:</v-flex>
                                        <v-flex>
                                            <a :href="`//${selected.website}`" target="_blank">{{ selected.website }}</a>
                                        </v-flex>-->
                                    <!--</v-layout>
                                    <v-divider></v-divider>
                                    <v-card class="card-shadow border-radius-xl">
                                        <v-row>
                                            <v-col cols="auto" class="my-auto">
                                                <div class="px-4 pt-5">
                                                    <h6 class="mb-0 text-typo text-h6 font-weight-bold">
                                                        積分記錄
                                                    </h6>
                                                </div>
                                            </v-col>-->
                                            <!--<v-col lg="4" md="6" class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                                                <div class="d-flex align-center">
                                                    <p class="mb-0 text-body text-xs ms-auto" v-if="switche == true">
                                                        月
                                                    </p>
                                                    <p class="mb-0 text-body text-xs ms-auto" v-if="switche == false">
                                                        年
                                                    </p>
                                                    <v-switch :ripple="false" class="mt-0 pt-0 ms-3 switch" v-model="switche" hide-details></v-switch>
                                                </div>
                                            </v-col>-->
                                        <!--</v-row>
                                        <div class="px-4 pt-6 pb-1">
                                            <div v-for="pointRecord in selected.pointRecords" :key="pointRecord.name">
                                                <v-list-item :key="pointRecord.vat" class="px-0 py-3 bg-gray-100 border-radius-lg p-6 mb-6">
                                                    <v-list-item-content class="px-6">
                                                        <div class="d-flex flex-column">
                                                            <h6 class="mb-3 text-sm text-typo font-weight-bold">
                                                                {{ pointRecord.name }}
                                                            </h6>
                                                            <span class="mb-2 text-xs text-body">時間:
                                                                <span class="text-dark font-weight-bold ms-sm-2">{{
                                                                    pointRecord.buyTime
                                                                    }}</span></span>
                                                            <span class="mb-2 text-xs text-body">積分:
                                                                <span class="text-dark ms-sm-2 font-weight-bold">{{
                                                                    pointRecord.point
                                                                    }}</span></span>
                                                        </div>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </div>
                                    </v-card>-->
                                </v-card>
                            </v-scroll-y-transition>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-col>
            <v-col md="8" sm="10" class="mx-auto">
                <v-card class="card-shadow border-radius-xl">
                    <!--<div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Datatable Search
        </h5>
        <p class="text-sm text-body font-weight-light mb-0">
          Features include sorting, searching, pagination, content-editing, and
          row selection.
        </p>
      </div>-->
                    <v-card-text class="px-0 py-0">
                        <v-data-table :headers="headers" :items="members" :search="search" class="table" :page.sync="page" hide-default-footer fixed-header @page-count="pageCount = $event" :items-per-page="itemsPerPage" mobile-breakpoint="0">
                            <template v-slot:top>
                                <v-toolbar flat height="80">
                                    <v-row>
                                        <v-col cols="12" md="5">
                                            <v-text-field hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" v-model="search" placeholder="搜尋">
                                                <template slot="prepend-inner">
                                                    <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2">search</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div class="d-flex align-center ms-2">
                                    <span class="text-sm font-weight-normal text-body">
                                        {{ item.name }}
                                    </span>
                                </div>
                            </template>
                            <template v-slot:item.email="{ item }">
                                <span class="text-sm font-weight-normal text-body">
                                    {{ item.email }}
                                </span>
                            </template>
                            <template v-slot:item.age="{ item }">
                                <span class="text-sm font-weight-normal text-body">
                                    {{ item.age }}
                                </span>
                            </template>
                            <template v-slot:item.salary="{ item }">
                                <span class="text-sm font-weight-normal text-body">
                                    {{ item.salary }}
                                </span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn @click="editItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                                    <v-icon size="14" class="material-icons-round">construction</v-icon>
                                </v-btn>
                                <v-btn @click="deleteItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                                    <v-icon size="14" class="material-icons-round">close</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="card-padding">
                        <v-row>
                            <v-col cols="6" lg="3" class="d-flex align-center">
                                <span class="text-body me-3 text-sm">每頁項目：</span>
                                <v-text-field hide-details type="number" outlined min="-1" max="15" background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage" @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page" class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              ">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="ml-auto d-flex justify-end">
                                <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#D81B60" v-model="page" :length="pageCount" circle></v-pagination>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Cookie from 'js-cookie';
import axios from 'axios';

const avatars = [
    '?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban',
    '?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
    '?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
    '?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
    '?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly'
]

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    name: "Groups",
    data() {
        return {
            switche: true,
            active: [],
            avatar: null,
            open: [],
            userDetails: [],
            itemsPerPage: 20,
            page: 20,
            search: "",
            pageCount: 1,

            /*
            userDetails: [{
                    "id": 6,
                    "name": "詹大中 (50,000+30,300)",
                    "email": "Shanna@melissa.tv",
                    "phone": "0955-538-456",
                    "pointsByMonth": "90,800",
                    "pointsByYear": "700,000",
                    "role": "經銷商",
                    "level": "一階",
                    "isPass": true,
                    pointRecords: [{
                            name: "#ABCIR3423",
                            buyTime: "2023/03/29 20:50",
                            point: "200",
                        },
                        {
                            name: "#ABCIR3424",
                            buyTime: "2023/03/28 21:50",
                            point: "30",
                        },
                        {
                            name: "#ABCIR3426",
                            buyTime: "2023/02/28 08:39",
                            point: "150",
                        },
                    ],
                },
                {
                    "id": 0,
                    "name": "王大明 (75,000+6,000)",
                    "email": "Shanna@melissa.tv",
                    "phone": "0955-538-456",
                    "pointsByMonth": "90,800",
                    "pointsByYear": "700,000",
                    "role": "經銷商",
                    "level": "一階",
                    "isPass": true,
                    pointRecords: [{
                            name: "#ABCIR3423",
                            buyTime: "2023/03/29 20:50",
                            point: "2",
                        },
                        {
                            name: "#ABCIR3424",
                            buyTime: "2023/03/28 21:50",
                            point: "3",
                        },
                        {
                            name: "#ABCIR3426",
                            buyTime: "2023/02/28 08:39",
                            point: "1",
                        },
                    ],
                },
            ],
            */
            id: 0,
            name: "",
            isPass: 0,
            roleStatus: 0,
            months: ["一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
            ],
            years: ["2024", "2023", "2022", "2021", "2020"],
            month: "",
            year: "",
            users: [],
            members: [],
            /*
            users: [{
                    "id": 1,
                    "name": "葉小志  (81,000)",
                    "isPass": true,
                    children: [{
                            "id": 101,
                            "name": "張雄文 (74,000+5,000)",
                            "isPass": true,
                        },
                        {
                            "id": 102,
                            "name": "陳怡中 (30,000+43,000)",
                            "isPass": true,
                            children: [{
                                    "id": 10201,
                                    "name": "高三郎 (85,000)",
                                    "isPass": true,
                                    children: [{
                                            "id": 1020101,
                                            "name": "黃智勳 (40,000+40,200)",
                                            "isPass": true,
                                        },
                                        {
                                            "id": 1020102,
                                            "name": "宋昌地 (75,000)",
                                            "isPass": true,
                                        },
                                    ],
                                },
                                {
                                    "id": 6,
                                    "name": "詹大中 (50,000+30,300)",
                                    "isPass": true,
                                },
                            ],
                        },
                    ],
                },
                {
                    "id": 2,
                    "name": "洪大發 (90,800)",
                    "isPass": true,
                },
                {
                    "id": 3,
                    "name": "張小黑 (60,000+20,200)",
                    "isPass": true,
                },
                {
                    "id": 4,
                    "name": "周心心 (70,000+10,200)",
                    "isPass": true,
                },
                {
                    "id": 5,
                    "name": "林良權 (80,300)",
                    "isPass": true,
                },
                {
                    "id": 8,
                    "name": "SQA (80,300)",
                    "isPass": false,
                },

            ],
            */

            headers: [{
                    text: "會員姓名",
                    align: "start",
                    cellClass: "border-bottom",
                    sortable: false,
                    value: "name",
                    class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
                },
                {
                    text: "會員等級",
                    value: "role",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "電子郵件",
                    value: "email",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "行動電話",
                    value: "phone",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
            ],


        };
    },
    mounted() {
        this.year = this.years[0]

        var today = new Date();
        console.log(today)
        console.log(today.getMonth())
        console.log(this.months[today.getMonth() - 1])
        this.month = this.months[today.getMonth()]
        //this.month = this.months[0]

        const token = Cookie.get('auth')
        const tokenParts = token.split('.')
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        let data = {
            'year': this.year,
            'month': this.month,
        };

        console.log(config['headers'])
        config['headers'].Authorization = 'Bearer '.concat(token)
        console.log(config)

        axios.get('/v1/group/' + this.userId.toString(), { params: data, headers: config['headers'] })
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)
                this.users = this.repsData.users
                this.isPass = this.repsData.isPass
                this.roleStatus = this.repsData.roleStatus
                this.id = this.repsData.id
                this.name = this.repsData.name
                this.userDetails = this.repsData.userDetails

                if (this.repsData.code === 40406) {
                    Cookie.set('auth', null)
                    localStorage.setItem("admin_token", null)
                    localStorage.removeItem("admin_token")
                    setTimeout(() => {
                        this.$router.push('/pages/authentication/signup/login');
                    }, 100)
                }


            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });

        axios.get('/v1/group/member/' + this.userId.toString(), { params: data, headers: config['headers'] })
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)
                this.members = this.repsData.members

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });

    },
    computed: {
        items() {
            return [{
                id: this.id,
                name: this.name,
                children: this.users,
                isPass: this.isPass,
                roleStatus: this.roleStatus,
            }]
        },
        /*
        members() {
            if (!this.active.length) return undefined

            const id = this.active[0]
            console.log(id)

            return this.userDetails.find(userDetail => userDetail.id === id).memberList
        },
        */
        selected() {
            if (!this.active.length) return undefined

            const id = this.active[0]
            console.log(id)

            const token = Cookie.get('auth')

            let data = {
                'year': this.year,
                'month': this.month,
            };

            console.log(config['headers'])
            config['headers'].Authorization = 'Bearer '.concat(token)
            console.log(config)

            axios.get('/v1/group/member/' + id.toString(), { params: data, headers: config['headers'] })
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)
                    this.members = this.repsData.members
                    //console.log("this.members")
                    //console.log(this.members)

                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });

            return this.userDetails.find(userDetail => userDetail.id === id)
            //return this.users.find(user => user.id.children[0].id === id)
        }
    },

    watch: {
        selected: 'randomAvatar'
    },
    methods: {
        /*
        async fetchUsers(item) {
            // Remove in 6 months and say
            // you've made optimizations! :)
            await pause(1000)

            return fetch('https://jsonplaceholder.typicode.com/users')
                .then(res => res.json())
                .then(json => (item.children.push(...json)))
                .catch(err => console.warn(err))
        },
        */
        changeYears(e) {
            this.year = e
            console.log(this.year)
        },

        changeMonths(e) {
            this.month = e
            console.log(this.month)
        },

        randomAvatar() {
            this.avatar = avatars[Math.floor(Math.random() * avatars.length)]
        },

        searchRangeGroup() {
            const token = Cookie.get('auth')
            const tokenParts = token.split('.')
            const body = JSON.parse(atob(tokenParts[1]))
            console.log(body)
            console.log(body.sub)

            this.userId = body.sub

            let data = {
                'year': this.year,
                'month': this.month,
            };

            console.log("this.config")
            console.log(config['headers'])
            config['headers'].Authorization = 'Bearer '.concat(token)
            console.log(config)

            axios.get('/v1/group/' + this.userId.toString(), { params: data, headers: config['headers'] })
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)
                    this.users = this.repsData.users
                    this.isPass = this.repsData.isPass
                    this.id = this.repsData.id
                    this.name = this.repsData.name
                    this.userDetails = this.repsData.userDetails

                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });

        },
    }

};
</script>