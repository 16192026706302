<template>
  <v-row no-gutters align="center" justify="center" class="d-flex h-100">
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-progress-circular v-if="isShowProgress" indeterminate color="#5E35B1" :size="70" :width="7"
          class="progress-overlay"></v-progress-circular>
      </v-row>
    </v-container>
    <v-col lg="4" md="7" class="mx-auto">
      <div class="
            align-center
            text-center
            justify-center
            bg-gradient-success
            shadow-success
            border-radius-lg
            mx-12
            " @click="lineLogin">

        <v-row align="center" justify="center">
          <v-col cols="1" class="align-center justify-center">
            <i class="fab fa-line text-white" style="font-size:1.5em"></i>
          </v-col>
          <v-col cols="8" class="align-center justify-center">
            <h4 class="text-h5 text-white font-weight-bold">快速註冊及登入</h4>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from "js-cookie";
import axios from "axios";
import { userLogin, userSignUp, userFastLogin, userData } from "@/apis/userService";

Vue.use(VueSweetalert2);

const Base64 = require("js-base64").Base64;

const client_id = "2005673202"; // yourClient_id 請設置為實際Channel ID 值
const redirect_uri = process.env.VUE_APP_REDIRECT_URI; // yourURI 請設置為實際Line developer 設定的重新導向網址
const bot_prompt = "normal";
const client_secret = "b20be1465398844207a50ffd27a041e0"; // yourClient_secret 請設置為實際Channel Secret 值
const line_notify_client_id = "JzeUPDYqbK770jo6ngZ8MQ";
const line_notify_client_secret = "S4tqOz1pxCyAvldOdbPQDsDMr0SEQRbSF27q3LFtTPz";


(async () => {
  try {
    const token = Cookie.get("auth");

    if (token === "null" || typeof token === "undefined") {
      console.log(token);
    } else {
      const tokenParts = token.split(".");
      console.log(tokenParts);
      const body = JSON.parse(atob(tokenParts[1]));
      console.log(body);
      console.log(body.sub);
      const userId = body.sub
    }

    // 使用 window.location.search 和 urlParams 獲取當前網頁 URL 中的查詢參數
    //const params = window.location.searchParams;
    const params = new URL(location.href).searchParams;
    console.log("params");
    console.log(params);

    const code = params.get("code");
    console.log(code);

    const state = params.get("state");
    console.log(state);

    const qs = require("qs");

    /*
      使用 Axios 發送 HTTP POST 請求到指定的 URL
      並指定 'Content-Type': 'application/x-www-form-urlencoded' 標頭以指示伺服器使用 URL 編碼形式解析參數
      grant_type：指定授權類型為 "authorization_code"
      code：授權碼，這個值是從 code 變數中取得的
      redirect_url：指定用戶授權完成後的重定向 URL
      client_id：用於識別應用程式的客戶端 ID
      client_secret：應用程式的客戶端密鑰
      這些參數使用 qs.stringify 函式轉換為 URL 編碼的形式，以符合 "application/x-www-form-urlencoded" 的請求格式
      Content-Type': 'application/x-www-form-urlencoded'：指定請求的內容類型為 URL 編碼形式
      */
    const tokenResponse = await axios.post(
      "https://api.line.me/oauth2/v2.1/token",
      qs.stringify({
        grant_type: "authorization_code",
        code: code,
        // yourURI 請設置為實際Line developer 設定的重新導向網址
        redirect_uri: redirect_uri,
        client_id: client_id,
        client_secret: client_secret,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    /*
      從 tokenResponse 的回應資料中取得 access_token 和 id_token。
      這些欄位是從 LINE 登入 API 取得的授權資訊。
      access_token 是用來作為驗證的令牌
      id_token 是使用者的身份令牌。
      */
    const accessToken = tokenResponse.data.access_token;
    const idToken = tokenResponse.data.id_token;
    console.log("accessToken");
    console.log(accessToken);
    console.log("idToken");
    console.log(idToken);

    /*
      使用 Axios 發送 HTTP POST 到 https://api.line.me/oauth2/v2.1/verify，驗證 id_token 以獲取包含使用者資訊的回應
      id_token：用於識別使用者的身份令牌
      client_id：用於識別應用程式的客戶端 ID
      使用 qs.stringify 函式轉換為 URL 編碼的形式，以符合 "application/x-www-form-urlencoded" 的請求格式
      Content-Type': 'application/x-www-form-urlencoded'：指定請求的內容類型為 URL 編碼形式。
      'Authorization': 'Bearer ' + accessToken：使用存取令牌進行身份驗證，將存取令牌放在 'Bearer ' 字符串之後。
      */
    const userInfoResponse = await axios.post(
      "https://api.line.me/oauth2/v2.1/verify",
      qs.stringify({
        id_token: idToken,
        client_id: client_id,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    /*
      根據需求，可以在登入後的處理中進行相應的操作，例如驗證用戶資訊、儲存登入狀態等。
      */
    const lineUserId = userInfoResponse.data.sub;
    const lineNickname = userInfoResponse.data.name;
    const lineNickemail = userInfoResponse.data.email;
    const linePicture = userInfoResponse.data.picture;
    console.log(userInfoResponse.data);
    console.log(lineUserId);
    console.log(lineNickname);
    console.log("lineNickemail")
    console.log(lineNickemail);
    console.log(linePicture);

    // 確認 LINE 登入後的使用者資訊是否有 email，若無 email 則不進行智勝網的註冊
    if ((lineNickemail === 'null') || (typeof (lineNickemail) === "undefined") || (lineNickemail === "")) {
      Vue.swal({
        title: 'LINE 尚未綁定 email',
        text: '請先將 LINE 綁定 email 後再進行快速註冊及登入',
        icon: 'error',
        confirmButtonText: '確認'
      });
    }

    userSignUp({
      parent_id: 3,
      name: lineNickname,
      password: "",
      gender: "",
      phone: "",
      email: lineNickemail,
      dealer_number: "3",
      //birthday: new Date(1981, 1, 1, 0, 0, 0), //Fri Apr 15 1988 10:22:15
      birthday: "",
      address: "",
      bank_code: 0,
      bank_account: 0,
      identity: "",
      timeout: 1000,
      signup_type: "LINE",
      line_uid: lineUserId,
      line_picture_link: linePicture,
    })
      .then((response) => {
        console.log(response);
        const errorCode = response.data.error_code;
        console.log(errorCode);

        if (errorCode === "") {
          userLogin({
            email: lineNickemail,
            password: "",
            login_type: "LINE"
          })
            .then((response) => {
              console.log(response);
              const errorCode = response.data.error_code;
              console.log(errorCode);

              if (errorCode === "") {
                const auth = response.data.token;
                //this.$store.commit('setAuth', this.auth);
                Cookie.set("auth", auth);
                console.log(auth);

                var tokenParts = auth.split(".");
                var body = JSON.parse(atob(tokenParts[1]));
                console.log("token body");
                console.log(body);
                console.log(body.sub);

                setTimeout(() => {
                  window.location.reload();
                }, 100);
              } else {
                console.log("test123")
                //this.alertError = true;
              }
            })
            .catch(function (error) {
              // 請求失敗處理
              console.log(error);
            });
        } else {
          console.log("test123")
          //this.alertError = true;
          //setTimeout(() => {
          //  this.$router.go();
          //}, this.timeout);
        }
      })
      .catch(function (error) {
        // 請求失敗處理
        console.log(error);
      });

  } catch (error) {
    console.log(error);
  }
})();

export default {
  name: "login",
  data() {
    return {
      timeout: 1000 * 1, // 3秒
      email: "",
      password: "",
      auth: "",
      userId: 0,
      alertSuccess: false,
      alertError: false,
      repsMessage: "",
      isShowProgress: false,
    };
  },
  mounted() {
    const token = Cookie.get("auth");

    console.log(token);
    console.log(typeof token);
    console.log(token === "null" || typeof token === "undefined");
    if (token === "null" || typeof token === "undefined") {
      //this.$router.push("/login");
      console.log(token)
    } else {
      const tokenParts = token.split(".");
      console.log(tokenParts);
      const body = JSON.parse(atob(tokenParts[1]));
      console.log(body);
      console.log(body.sub);

      userFastLogin({
        user_id: body.sub,
      })
        .then((response) => {
          console.log(response);
          this.repsMessage = response.data.message;
          const errorCode = response.data.error_code;
          console.log(this.repsMessage);
          console.log(errorCode);

          if (errorCode === "") {
            this.auth = response.data.token;
            this.$store.commit("setAuth", this.auth);
            Cookie.set("auth", null);
            Cookie.set("auth", this.auth);
            console.log(this.auth);

            var tokenParts = this.auth.split(".");
            var body = JSON.parse(atob(tokenParts[1]));
            console.log("token body");
            console.log(body);
            console.log(body.sub);

            this.userId = body.sub;
            console.log(this.userId);

            userData(this.userId)
              .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.name = this.repsData.name
                this.address = this.repsData.address
                this.gender = this.repsData.gender === 2 ? "女性" : "男性" //this.genders[this.repsData.gender - 1]
                this.phone = this.repsData.phone
                this.email = this.repsData.email
                this.email_pass_status = this.repsData.email_pass_status
                this.phone_pass_status = this.repsData.phone_pass_status
                this.role = this.repsData.role
                this.due_date = this.repsData.due_date
                this.birthday = this.repsData.birthday
                this.bank_code = this.repsData.bank_code
                this.bank_account = this.repsData.bank_account
                this.identity = this.repsData.identity
                this.line_notify_token = this.repsData.line_notify_token

                if (this.phone === "" || this.phone_pass_status === 0 || this.birthday === "" || this.gender === "" || this.address === "" || this.line_notify_token === "") {
                  if (this.phone === "" || this.phone_pass_status === 0) {
                    this.$router.push("/profile-settings/phone");
                  } else {

                    this.$router.push("/profile-settings");
                  }
                } else {
                  this.isShowProgress = true;
                  setTimeout(() => {
                    this.isShowProgress = false;
                    this.$router.push("/homepage");
                  }, 1000);

                }
              })
              .catch(error => { // 请求失败处理
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);
        });
    }
  },
  methods: {
    lineLogin() {
      // 根據指定的 client_id、redirect_uri、scope 等參數組合出一個 LINE 登入的連結
      let link = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=login&bot_prompt=aggressive&friendship_status_changed=true&scope=profile%20openid%20email`;
      // 將頁面重新導向到該連結
      window.location.href = link;
    },
  },
};
</script>
<style scoped>
/* 使進度條在螢幕上居中，並且至於最頂層 */
.progress-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>