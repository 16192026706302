<template>
    <div>
        <v-card class="border-radius-xl mt-6">
            <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="reports" :search="search" class="table border-radius-xl" :page.sync="page" hide-default-footer fixed-header @page-count="pageCount = $event" :items-per-page="itemsPerPage" mobile-breakpoint="0">
                    <template v-slot:top>
                        <v-toolbar flat height="80" class="border-radius-xl">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" v-model="search" placeholder="搜尋">
                                        <template slot="prepend-inner">
                                            <v-icon color="#adb5bd" size="18px" class="material-icons-round mt-n2">search</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-select v-model="year" :items="years" color="#e91e63" class="font-size-input input-style" @change="changeYears" single-line>
                                    </v-select>
                                </v-col>
                                <v-col cols="2">
                                    <v-select v-if="switche == true" v-model="month" :items="months" label="一月" color="#e91e63" class="font-size-input input-style" @change="changeMonths" single-line>
                                    </v-select>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn @click="searchRangeBonusReport" :ripple="false" class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    ">確認</v-btn>
                                </v-col>
                                <!--<v-col cols="2">
                                    <v-row>
                                        <v-col>
                                            <p v-if="switche == true">
                                                月
                                            </p>
                                            <p v-if="switche == false">
                                                年
                                            </p>
                                        </v-col>
                                        <v-col>
                                            <v-switch :ripple="false" v-model="switche" hide-details></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-col>-->
                            </v-row>
                        </v-toolbar>
                    </template>
                    <template v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )" v-slot:[`item.${header.value}`]="{ header, value }">
                        {{ header.formatter(value) }}
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
                <v-row>
                    <v-col cols="6" lg="3" class="d-flex align-center">
                        <span class="text-body me-3 text-sm">每頁項目：</span>
                        <v-text-field hide-details type="number" outlined min="-1" max="15" background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage" @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page" class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              ">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="ml-auto d-flex justify-end">
                        <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#d81b60" v-model="page" :length="pageCount" circle></v-pagination>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
// import reports from "./Reports";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Authorization",
        "Content-Type": "application/json;",
    }
}



export default {
    name: "table-reports",
    data() {
        return {
            dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuStart: false,
            menuEnd: false,
            switche: true,
            page: 1,
            pageCount: 0,
            itemsPerPage: 20,
            page: 20,
            search: "",
            pageCount: 1,
            reports: [],
            search: "",
            months: ["一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
            ],
            years: ["2024", "2023", "2022", "2021", "2020"],
            month: "",
            year: "",
            headers: [{
                    text: "姓名",
                    align: "start",
                    sortable: false,
                    value: "name",
                    class: "text-secondary font-weight-bolder opacity-7 border-bottom",
                },
                /*{
                    text: "上線姓名",
                    value: "parent_name",
                    class: "text-secondary font-weight-bolder opacity-7",
                },*/
                {
                    text: "積分",
                    value: "point_member",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "階級",
                    value: "level",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "合格狀態",
                    value: "pass_status",
                    formatter: this.passStatusFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "是否自動補救",
                    value: "is_remedy",
                    formatter: this.autoRemedyFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "補救後積分",
                    value: "point",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "個人獎金",
                    value: "bonus_self",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "組織獎金",
                    value: "bonus_group",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "年終獎金",
                    value: "bonus_year",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "積分值",
                    value: "point_rate",
                    //formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "轉帳手續費",
                    value: "fee",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "所得代扣繳",
                    value: "tax_withheld",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "二代健保",
                    value: "second_nhi",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "實際提撥",
                    value: "bonus_real",
                    formatter: this.numFormat,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
            ],
        };
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
        pages() {
            return this.pagination.rowsPerPage ?
                Math.ceil(this.items.length / this.pagination.rowsPerPage) :
                0;
        },
    },
    mounted() {
        this.year = this.years[0]

        var today = new Date();
        console.log(today)
        console.log(today.getMonth())
        console.log(this.months[today.getMonth() - 1])
        this.month = this.months[today.getMonth()]

        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof token)
        console.log((token === 'null') || (typeof(token) === "undefined"))
        if ((token === 'null') || (typeof(token) === "undefined")) {
            this.$router.push('/pages/authentication/signup/login');
        }

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        console.log("this.config")
        console.log(config['headers'])
        config['headers'].Authorization = 'Bearer '.concat(token)
        console.log(config)

        let data = {
            'year': this.year,
            'month': this.month,
            'type': "month"
        };

        axios.get('/v1/bonus-report', { params: data, headers: config['headers'] })
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.reports = this.repsData.list
                console.log(this.reports)
                this.$emit("value-reports", this.reports)

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });
    },
    methods: {
        numFormat(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        passStatusFormat(value) {
            if (value === 1) {
                return "合格";
            } else {
                return "";
            }
        },

        autoRemedyFormat(value) {
            if (value === 1) {
                return "Yes";
            } else {
                return "No";
            }
        },

        dateFormat(value) {
            var result = ""
            if (value === "") {
                result = ""

            } else {
                var date = new Date(value);
                result = "" + date.getFullYear() + "-" + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + "-" + (date.getDate() > 9 ? '' : '0') + date.getDate();

            }
            return result;
        },

        changeYears(e) {
            this.year = e
            console.log(this.year)
        },

        changeMonths(e) {
            this.month = e
            console.log(this.month)
        },

        searchRangeBonusReport() {
            const token = Cookie.get('auth')

            console.log(token)
            console.log(typeof token)
            console.log((token === 'null') || (typeof(token) === "undefined"))
            if ((token === 'null') || (typeof(token) === "undefined")) {
                this.$router.push('/pages/authentication/signup/login');
            }

            const tokenParts = token.split('.')
            console.log(tokenParts)
            const body = JSON.parse(atob(tokenParts[1]))
            console.log(body)
            console.log(body.sub)

            this.userId = body.sub

            if (this.switche === false) {
                this.month = ""
            }
            //console.log("this.switche")
            //console.log(this.switche)
            //console.log(this.month)

            let data = {
                'year': this.year,
                'month': this.month,
                'type': "month"
            };

            config['headers'].Authorization = 'Bearer '.concat(token)
            console.log(config)

            axios.get('/v1/bonus-report', { params: data, headers: config['headers'] })
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)

                    this.reports = this.repsData.list
                    console.log(this.reports)
                    this.$emit("value-reports", this.reports)

                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });
        },


    },
};
</script>