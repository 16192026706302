<template>
  <v-row class="align-center d-flex h-100">
    <v-col lg="4" md="7" class="mx-auto">
      <v-card class="card-shadow border-radius-xl py-1">
        <div
          class="
            card-padding
            text-center
            mt-n7
            bg-gradient-success
            shadow-success
            border-radius-lg
            mx-4
          "
        >
          <h4 class="text-h4 text-white font-weight-bold">快速註冊</h4>
          <v-row class="mt-1">
            <v-col cols="12" class="me-auto d-flex justify-center">
              <v-btn
                icon
                outlined
                rounded
                color="transparent"
                class="text-center border-radius-md"
              >
                <i class="fab fa-line text-white text-lg fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="mt-2 position-relative text-center">
          <p
            class="
              text-sm
              font-weight-bold
              mb-2
              text-secondary text-border
              d-inline
              z-index-2
              bg-white
              px-3
            "
          >
            或
          </p>
        </div>
        <div class="card-padding">
          <v-text-field
            label="姓名"
            placeholder="Name"
            v-model="name"
            color="#e91e63"
            required
            class="font-size-input input-style"
          ></v-text-field>

          <v-text-field
            label="電子郵件"
            placeholder="Email"
            v-model="email"
            color="#e91e63"
            required
            class="font-size-input input-style"
          ></v-text-field>

          <!--<v-text-field
            label="電話"
            placeholder="Phone"
            v-model="phone"
            color="#e91e63"
            required
            class="font-size-input input-style"
          ></v-text-field>-->

          <v-text-field
            label="密碼"
            placeholder="Password"
            v-model="password"
            color="#e91e63"
            required
            type="password"
            class="font-size-input input-style"
          ></v-text-field>

          <v-checkbox
            v-model="checkbox"
            color="#141727"
            :ripple="false"
            class="ma-0 checkbox-custom checkbox-thinner"
            hide-details
          >
            <template v-slot:label>
              <span class="text-body text-sm ls-0"
                >我同意
                <a
                  href="javascript:;"
                  class="text-dark font-weight-bolder text-decoration-none"
                  >服務條款和條件</a
                ></span
              >
            </template>
          </v-checkbox>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            "
            color="#5e72e4"
            small
            @click="postSignUp"
            >註冊</v-btn
          >
          <p class="text-sm text-body mt-3 mb-0">
            已經有帳號了嗎？
            <a
              href="javascript:;"
              class="text-dark text-decoration-none font-weight-bolder"
              @click="goToLogin"
              >登入</a
            >
          </p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import { userSignUp, userLogin } from "@/apis/userService";

Vue.use(VueSweetalert2);

export default {
  name: "sign-up-basic",
  data() {
    return {
      checkbox: false,
      name: "",
      password: "",
      email: "",
      phone: "",
      dateBirthday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },
  methods: {
    postSignUp() {
      if (this.checkbox) {
        userSignUp({
          parent_id: 1,
          name: this.name,
          password: this.password,
          gender: "",
          phone: this.phone,
          email: this.email,
          dealer_number: "3",
          birthday: this.dateBirthday,
          address: "",
          bank_code: 0,
          bank_account: 0,
          identity: "",
          timeout: 1000,
        })
          .then((response) => {
            console.log(response);
            this.repsMessage = response.data.message;
            const errorCode = response.data.error_code;
            console.log("response message.");
            console.log(this.repsMessage);
            console.log(errorCode);
            
            if (errorCode === "") {
              userLogin({
                email: this.email,
                password: this.password,
              })
                .then((response) => {
                  console.log(response);
                  this.repsMessage = response.data.message;
                  const errorCode = response.data.error_code;
                  console.log(this.repsMessage);
                  console.log(errorCode);

                  if (errorCode === "") {
                    this.alertSuccess = true;
                    this.auth = response.data.token;
                    //this.$store.commit('setAuth', this.auth);
                    Cookie.set("auth", this.auth);
                    console.log(this.auth);

                    var tokenParts = this.auth.split(".");
                    var body = JSON.parse(atob(tokenParts[1]));
                    console.log("token body");
                    console.log(body);
                    console.log(body.sub);

                    this.userId = body.sub;
                    console.log(this.userId);

                    setTimeout(() => {
                      this.$router.push("/profile");
                    }, this.timeout);
                  } else {
                    this.alertError = true;
                  }
                })
                .catch(function (error) {
                  // 请求失败处理
                  console.log(error);
                });
            } else {
              this.alertError = true;
              setTimeout(() => {
                this.$router.go();
              }, this.timeout);
            }
              
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error);
          });
      } else {
        this.alertError = true;
        this.repsMessage = "請勾選同意條款和條件";
      }
    },
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
