import axios from "./utils";

export const userSignUp = (signData) => {
  return axios.post(`/v1/signup`, signData);
};

export const userLogin = (loginData) => {
  return axios.post(`/v1/login`, loginData);
};

export const userFastLogin = (fastLoginData) => {
  return axios.post(`/v1/fast-login`, fastLoginData);
};

export const userDataUpdate = (userData, userId) => {
  return axios.put(`/v1/member/`+ userId.toString(), userData);
};

export const userData = (userId) => {
  return axios.get(`/v1/user/`+ userId.toString());
};