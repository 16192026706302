<template>
  <v-container fluid class="py-3 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/ai-robot-overview.jpg')}) no-repeat center center fixed; background-size: cover; height: 100vh;`">
      <div class="text-center">
        <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="#1A237E"
          content-class="text text-h4 text-center mb-4" :timeout="timeout" absolute centered elevation="24">{{
            repsMessage
          }}</v-snackbar>
        <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="#1A237E"
          content-class="text text-h4 text-center mb-4" :timeout="timeout" absolute centered elevation="24">{{
            repsMessage
          }}</v-snackbar>
      </div>
      <v-row class="mx-2">
        <v-col md="8" class="me-auto text-start">
          <h5 class="text-h5 text-typo font-weight-bold mb-2">
            AI機器人管理
          </h5>
          <p class="text-typo font-weight-light">
            各項遊戲透過AI機器人進行遊玩。 <br />
          </p>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <!--<v-col><p class="text-body font-weight-light">
        無執行代理機器人。 <br />
      </p></v-col>-->
        <v-col lg="4" md="6" cols="12" v-for="card in cards" :key="card.title" class="mb-3">
          <v-card class="card-shadow border-radius-xl">
            <div class="px-4 py-3">
              <div class="d-flex">

                <v-row no-gutters class="text-center">
                  <v-col cols="12">
                    <v-avatar size="80" class="border-radius-xl bg-gradient-default mt-n6 text-center">
                      <v-img :src="card.image" width="100%" height="100%"> </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <h6 class="text-h6 font-weight-bold my-2" style="color:#7986CB">
                        {{ card.title }}
                      </h6>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!--<p class="text-sm mt-4 text-body font-weight-light">
              停止代理機器人請於右上角按下停止。 <br />
              重新啟動請先按下刪除後再至賓果EX重新設定啟動。
            </p>-->
              <hr class="horizontal mb-4 font-weight-bold" :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                style="color:#1A237E;background-color:#1A237E" />

              <p class="text-sm mt-4 text-body text-typo font-weight-bold font-weight-light">
                {{ card.description }}
              </p>
              <hr class="horizontal dark mb-3" />
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn elevation="0" :ripple="false" height="43" class="
                    font-weight-bold
                    text-uppercase
                    text-white
                    py-2
                    px-6
                    text-xs
                  " :disabled="btnJobStop" color="#1A237E" @click="showStopAlert">
                    停止機器人</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>


  </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from "js-cookie";
import axios from "axios";
import { jobStatusUpDate, jobDelete, jobGetStatus } from "@/apis/jobService";

const Base64 = require("js-base64").Base64;

Vue.use(VueSweetalert2);

export default {
  name: "RobotAgentOverview",
  data: function () {
    return {
      userId: 1,
      alertSuccess: false,
      alertError: false,
      repsMessage: "",
      timeout: 1000 * 3, // 3秒
      btnJobStop: true,
      cards: [
        {
          image: require("@/assets/img/games/01.png"),
          title: "賓果EX",
          description:
            "啟動Aiwin智能機器人自動下注【】遊戲，依照您設定投注策略，透過AI機器人執行投注。幫助您高效地管理投注過程。",
        },

      ],
      sidebarTheme: "dark",
    };
  },
  mounted() {
    const token = Cookie.get("auth");

    console.log(token);
    console.log(typeof token);
    console.log(token === "null" || typeof token === "undefined");
    if (token === "null" || typeof token === "undefined") {
      this.$router.push("/login");
    } else {
      const tokenParts = token.split(".");
      console.log(tokenParts);
      const body = JSON.parse(atob(tokenParts[1]));
      console.log(body);
      console.log(body.sub);
      this.userId = body.sub

      jobGetStatus(this.userId)
        .then((response) => {
          console.log(response);
          this.repsMessage = response.data.message;
          const taskDescription = response.data.task_description;
          const jobStatus = response.data.job_status;
          const stopButtonClicked = response.data.stop_button_clicked;
          console.log("jobStatus");
          console.log(jobStatus);

          this.cards[0].title = taskDescription;

          if (taskDescription === "賓果EX") {
            this.cards[0].image = require("@/assets/img/games/01.png");
            var str = this.cards[0].description;
            var res = str.replace("【】", "【" + taskDescription + "】");
            this.cards[0].description = res;
          } else if (taskDescription === "WG彩票") {
            this.cards[0].image = require("@/assets/img/games/02.png");
            var str = this.cards[0].description;
            var res = str.replace("【】", "【" + taskDescription + "】");
            this.cards[0].description = res;
          } else {
            console.log("taskDescription is not match");
          }

          if (typeof jobStatus === 'undefined') {
            this.btnJobStop = true;
          } else {
            if (stopButtonClicked === 1) {
              this.btnJobStop = true;
            } else {
              this.btnJobStop = false;
            }
          }
        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);
        });
    }
  },
  methods: {
    jobStop() {
      jobStatusUpDate({
        status: "PENDING",
        stop_button_clicked: 1,
      }, this.userId)
        .then((response) => {
          console.log(response);
          this.repsMessage = response.data.message;
          const errorCode = response.data.error_code;
          console.log(this.repsMessage);
          console.log(errorCode);

          if (errorCode === "") {
            if (this.repsMessage === 'Successful Operation.') {
              console.log(this.repsMessage);
            } else {
              this.alertSuccess = true;
            }
          } else {
            this.alertError = true;
          }
          setTimeout(() => {
            this.$router.push("/homepage")
          }, this.timeout);
        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);
        });
    },
    jobDel() {
      jobDelete(this.userId)
        .then((response) => {
          console.log(response);
          this.repsMessage = response.data.message;
          const errorCode = response.data.error_code;
          console.log(this.repsMessage);
          console.log(errorCode);

          if (errorCode === "") {
            this.alertSuccess = true;

          } else {
            this.alertError = true;
          }
        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);
        });
    },
    showStopAlert() {
      this.$swal({
        title: "停止並刪除AI機器人？",
        text: "您確認要停止自動下注且刪除AI機器人！",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "是，停止並刪除它！",
        cancelButtonText: "否",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire({
            title: "已停止並刪除！",
            text: "先停止機器人執行動作，發送最後的派彩資訊，後續將刪除AI機器人。",
            icon: "success",
            confirmButtonText: "確認",
          }).then((result) => {
            if (result.isConfirmed) {
              this.jobStop();
            }
          })
        }
      });
    },
  },
};
</script>
