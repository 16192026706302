<template>
    <div>
        <v-row>
            <v-col md="12">
                <v-row>
                    <v-col sm="2" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4" @click="searchesMemberByType('all')">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-default border-radius-xl mt-n8" class="shadow-dark" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">person_add</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                      text-sm
                      mb-0
                      text-capitalize text-body
                      font-weight-light
                    ">
                                        全部會員
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{ allNum }}
                                    </h4>
                                </v-col>
                            </v-row>
                            <hr class="dark horizontal mt-3 mb-4" />
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <p class="mb-0 text-body">
                                        <!--<span class="text-success text-sm font-weight-bolder">+5%</span>
                                        <span class="font-weight-light ms-1">than last week</span>-->
                                        <span class="font-weight-light ms-1">目前所有會員</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="2" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4" @click="searchesMemberByType('dealer')">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-primary border-radius-xl mt-n8" class="shadow-primary" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">person_add</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                      text-sm
                      mb-0
                      text-capitalize text-body
                      font-weight-light
                    ">
                                        經銷商
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{ dealerNum }}
                                    </h4>
                                </v-col>
                            </v-row>
                            <hr class="dark horizontal mt-3 mb-4" />
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <p class="mb-0 text-body">
                                        <!--<span class="text-success text-sm font-weight-bolder">+5%</span>
                                        <span class="font-weight-light ms-1">than last week</span>-->
                                        <span class="font-weight-light ms-1">目前經銷商</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="2" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4" @click="searchesMemberByType('groups')">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-primary border-radius-xl mt-n8" class="shadow-primary" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">person_add</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                      text-sm
                      mb-0
                      text-capitalize text-body
                      font-weight-light
                    ">
                                        團購主
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{ groupsNum }}
                                    </h4>
                                </v-col>
                            </v-row>
                            <hr class="dark horizontal mt-3 mb-4" />
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <p class="mb-0 text-body">
                                        <!--<span class="text-success text-sm font-weight-bolder">+5%</span>
                                        <span class="font-weight-light ms-1">than last week</span>-->
                                        <span class="font-weight-light ms-1">目前團購主</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="2" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4" @click="searchesMemberByType('member')">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-primary border-radius-xl mt-n8" class="shadow-primary" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">person_add</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                      text-sm
                      mb-0
                      text-capitalize text-body
                      font-weight-light
                    ">
                                        一般會員
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{ memberNum }}
                                    </h4>
                                </v-col>
                            </v-row>
                            <hr class="dark horizontal mt-3 mb-4" />
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <p class="mb-0 text-body">
                                        <span class="font-weight-light ms-1">目前一般會員</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="2" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4" @click="searchesMemberByType('expiring')">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-info border-radius-xl mt-n8" class="shadow-info" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">person_add</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                      text-sm
                      mb-0
                      text-capitalize text-body
                      font-weight-light
                    ">
                                        即將到期經銷商
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{ expiringDealerNum }}
                                    </h4>
                                </v-col>
                            </v-row>
                            <hr class="dark horizontal mt-3 mb-4" />
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <p class="mb-0 text-body">
                                        <span class="text-success text-sm font-weight-bolder">7</span>
                                        <span class="font-weight-light ms-1">天後，即將到期的經銷商</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col sm="2" cols="12">
                        <v-card class="mb-6 card-shadow border-radius-xl py-4" @click="searchesMemberByType('expire')">
                            <v-row no-gutters class="px-4">
                                <v-col sm="4">
                                    <v-avatar color="bg-gradient-info border-radius-xl mt-n8" class="shadow-primary" height="64" width="64">
                                        <v-icon class="material-icons-round text-white" size="24">person_add</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col sm="8" class="text-end">
                                    <p class="
                      text-sm
                      mb-0
                      text-capitalize text-body
                      font-weight-light
                    ">
                                        已到期經銷商
                                    </p>
                                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                        {{ dueDateDealerNum }}
                                    </h4>
                                </v-col>
                            </v-row>
                            <hr class="dark horizontal mt-3 mb-4" />
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <p class="mb-0 text-body">
                                        <span class="font-weight-light ms-1">已經到期的經銷商</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-card class="card-shadow border-radius-xl">
            <!--<div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Datatable Search
        </h5>
        <p class="text-sm text-body font-weight-light mb-0">
          Features include sorting, searching, pagination, content-editing, and
          row selection.
        </p>
      </div>-->
            <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="users" :search="search" class="table" :page.sync="page" hide-default-footer fixed-header @page-count="pageCount = $event" :items-per-page="itemsPerPage" mobile-breakpoint="0">
                    <template v-slot:top>
                        <v-toolbar flat height="80">
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" v-model="search" placeholder="姓名、行動電話、電子信箱">
                                        <template slot="prepend-inner">
                                            <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2">search</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" elevation="0" :ripple="false" height="43" class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    ">新增會員</v-btn>
                                </template>
                                <v-card class="card-shadow border-radius-xl">
                                    <div class="card-header-padding card-border-bottom">
                                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                                            formTitle
                                            }}</span>
                                    </div>
                                    <v-card-text class="card-padding">
                                        <v-container class="px-0">
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field v-model="editedItem.name" label="姓名" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <!--<v-text-field
                            v-model="editedItem.role"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="會員等級"
                          ></v-text-field>-->
                                                    <v-select label="會員等級" v-model="editedItem.role" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43" :items="['會員', '經銷商', '團購主']"></v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.password" label="密碼" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="電子信箱" v-model="editedItem.email" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43" :disabled="emailValidation"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="行動電話" v-model="editedItem.phone" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43" :disabled="phoneValidation"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <!--<v-text-field v-model="editedItem.phoneNumber" hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat filled solo height="43" placeholder="行動電話"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.dueDate" hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat filled solo height="43" placeholder="到期日"></v-text-field>-->
                                                    <v-menu v-model="menuDueDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="editedItem.due_date" label="到期日" color="#e91e63" readonly outlined dense hide-details class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                " v-bind="attrs" v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.due_date" @input="menuDueDate = false" color="green lighten-1" locale="zh-tw"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.identity" label="身份字號/統一編號" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="editedItem.bank_code" label="銀行代碼" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="editedItem.bank_account" label="銀行帳戶" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.address" label="地址" outlined hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat height="43"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="card-padding d-flex justify-end">
                                        <v-btn @click="close" elevation="0" :ripple="false" height="43" class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      ">取消離開</v-btn>
                                        <v-btn @click="save(editedItem)" elevation="0" :ripple="false" height="43" class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      ">儲存</v-btn>
                                    </v-card-actions>
                                </v-card>
                                <div class="text-center">
                                    <v-progress-circular indeterminate :size="50" :width="5" color="blue" v-if="isShowProgress"></v-progress-circular>
                                    <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                                    <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                                </div>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card class="card-shadow card-padding border-radius-xl">
                                    <v-card-title class="pt-0 text-h5 text-typo justify-center">連結前往此會員經銷獎金查詢</v-card-title>
                                    <v-card-actions class="pb-0">
                                        <v-spacer></v-spacer>
                                        <v-btn @click="closeDelete" elevation="0" :ripple="false" height="43" class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      ">取消</v-btn>
                                        <v-btn @click="goToThisMemberBonus(editedItem.id)" elevation="0" :ripple="false" height="43" class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      ">確認</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogChangeMember" max-width="500px">
                                <v-card class="card-shadow card-padding border-radius-xl">
                                    <v-card-title class="pt-0 text-h5 text-typo justify-center">切換此會員身份</v-card-title>
                                    <v-card-actions class="pb-0">
                                        <v-spacer></v-spacer>
                                        <v-btn @click="closeChangeMember" elevation="0" :ripple="false" height="43" class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      ">取消</v-btn>
                                        <v-btn @click="goToThisMember(editedItem.id)" elevation="0" :ripple="false" height="43" class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      ">確認</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div class="d-flex align-center ms-2">
                            <span class="text-sm font-weight-normal text-body">
                                {{ item.name }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.role="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.role }}
                        </span>
                    </template>
                    <template v-slot:item.password="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.password }}
                        </span>
                    </template>
                    <template v-slot:item.email="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.email }}
                        </span>
                    </template>
                    <template v-slot:item.phoneNumber="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.phone }}
                        </span>
                    </template>
                    <template v-slot:item.dueDate="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.due_date }}
                        </span>
                    </template>
                    <template v-slot:item.identity="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.identity }}
                        </span>
                    </template>
                    <template v-slot:item.bankCode="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.bank_code }}
                        </span>
                    </template>
                    <template v-slot:item.financialAccount="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.bank_account }}
                        </span>
                    </template>
                    <template v-slot:item.address="{ item }">
                        <span class="text-sm font-weight-normal text-body">
                            {{ item.address }}
                        </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="editItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                            <v-icon size="14" class="material-icons-round">construction</v-icon>
                        </v-btn>
                        <v-btn @click="deleteItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                            <v-icon size="14" class="material-icons-round">link</v-icon>
                        </v-btn>
                        <v-btn @click="changeItem(item)" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                            <v-icon size="14" class="material-icons-round">person</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
                <v-row>
                    <v-col cols="6" lg="3" class="d-flex align-center">
                        <span class="text-body me-3 text-sm">每頁項目：</span>
                        <v-text-field hide-details type="number" outlined min="-1" max="15" background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage" @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page" class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              ">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="ml-auto d-flex justify-end">
                        <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#D81B60" v-model="page" :length="pageCount" circle></v-pagination>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
//import users from "./Users";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from "js-cookie";
import axios from "axios";

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    },
};

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const Base64 = require("js-base64").Base64;

Vue.use(VueSweetalert2);

export default {
    name: "paginated-tables",
    data() {
        return {
            emailValidation: false,
            phoneValidation: false,
            timeout: 1000,
            menuDueDate: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 20,
            page: 20,
            search: "",
            pageCount: 1,
            dialog: false,
            dialogDelete: false,
            dialogChangeMember: false,
            users: [],
            search: "",
            alertSuccess: false,
            alertError: false,
            repsMessage: "",
            dateBirthday: new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                )
                .toISOString()
                .substr(0, 10),
            editedIndex: -1,
            dealerNum: 0,
            expiringDealerNum: 0,
            dueDateDealerNum: 0,
            memberNum: 0,
            editedItem: {
                name: "",
                role: "",
                gender: "",
                email: "",
                phone: "",
                due_date: "",
                identity: "",
                bank_code: 0,
                bank_account: 0,
                address: "",
                password: "",
            },
            defaultItem: {
                name: "",
                role: "",
                email: "",
                phone: "",
                due_date: "",
                identity: "",
                bank_code: 0,
                bank_account: 0,
                address: "",
                password: "",
            },
            headers: [{
                    text: "姓名",
                    align: "start",
                    cellClass: "border-bottom",
                    sortable: false,
                    value: "name",
                    class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
                },
                {
                    text: "會員等級",
                    value: "role",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "電子信箱",
                    value: "email",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "行動電話",
                    value: "phone",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "到期日",
                    value: "due_date",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "身份字號/統一編號",
                    value: "identity",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "銀行代碼",
                    value: "bank_code",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "銀行帳戶",
                    value: "bank_account",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "編輯",
                    value: "actions",
                    sortable: false,
                    class: "text-secondary font-weight-bolder opacity-7",
                },
            ],
        };
    },
    mounted() {
        const token = Cookie.get("auth");

        console.log(token);
        console.log(typeof token);
        console.log(token === "null");
        if (token === "null") {
            this.$router.push("/pages/authentication/signup/login");
        }

        const tokenParts = token.split(".");
        console.log(tokenParts);
        const body = JSON.parse(atob(tokenParts[1]));
        console.log(body);
        console.log(body.sub);

        this.userId = body.sub;

        let data = {
            type: "all",
        };

        axios
            .get("/v1/member", { params: data }, this.config)
            .then((response) => {
                this.repsData = response.data;
                console.log(this.repsData);

                this.users = this.repsData.list;
                console.log(this.users);

                this.allNum = this.repsData.all_num;
                this.dealerNum = this.repsData.dealer_num;
                this.groupsNum = this.repsData.groups_num;
                this.expiringDealerNum = this.repsData.expiring_dealer_num;
                this.dueDateDealerNum = this.repsData.due_date_dealer_num;
                this.memberNum = this.repsData.member_num;
            })
            .catch((error) => {
                // 请求失败处理
                console.log(error);
            });
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            if (this.editedIndex === -1) {
                console.log("test")
            } else {
                console.log(this.editedItem.email_validation)
                if (this.editedItem.email_validation === 1) {
                    this.emailValidation = true

                }
                console.log(this.editedItem.phone_validation)
                if (this.editedItem.phone_validation === 1) {
                    this.phoneValidation = true

                }
            }


        },

        deleteItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        changeItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogChangeMember = true;
        },

        deleteItemConfirm() {
            this.users.splice(this.editedIndex, 1);
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeChangeMember() {
            this.dialogChangeMember = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save(editedItem) {
            console.log("editedItem");
            console.log(editedItem);

            if (this.editedIndex === -1) {
                axios
                    .post(
                        "/v1/signup", {
                            parent_id: 3, //setting shopping cart id
                            name: editedItem.name,
                            password: editedItem.password,
                            gender: "",
                            phone: editedItem.phone,
                            email: editedItem.email,
                            dealer_number: "3", //setting shopping cart id
                            birthday: this.dateBirthday,
                            address: editedItem.address,
                            bank_code: parseInt(editedItem.bank_code),
                            bank_account: parseInt(editedItem.bank_account),
                            identity: editedItem.identity,
                        },
                        config
                    )
                    .then((response) => {
                        console.log(response);
                        this.repsMessage = response.data.message;
                        const errorCode = response.data.error_code;
                        console.log(this.repsMessage);
                        console.log(errorCode);

                        if (errorCode === '') {
                            this.$swal(
                                "",
                                "成功新增 " + editedItem.name + " 資訊。",
                                "success"
                            );

                            this.close();

                            setTimeout(() => {
                                this.$router.go();
                            }, this.timeout)
                        } else {
                            this.alertError = true
                        }
                    })
                    .catch(function(error) {
                        // 请求失败处理
                        console.log(error);
                    });
            } else {
                console.log(editedItem.role);
                console.log(editedItem.bank_code);
                console.log(editedItem.bank_account);
                if (editedItem.role === "經銷商" || editedItem.role === "團購主") {
                    if (
                        editedItem.due_date === "" ||
                        editedItem.address === "" ||
                        editedItem.bank_code === "" ||
                        editedItem.bank_code === 0 ||
                        editedItem.bank_code === null ||
                        editedItem.bank_account === "" ||
                        editedItem.bank_account === 0 ||
                        editedItem.bank_account === null ||
                        editedItem.identity === ""
                    ) {
                        //editedItem.role = "會員";
                        this.$swal("", "轉經銷商或團購主，請填入完整資訊。", "error");
                        return;
                    }
                }

                var pwd = editedItem.password;
                if (typeof pwd === "undefined") {
                    pwd = "";
                }

                axios
                    .put(
                        "/v1/member/" + editedItem.id.toString(), {
                            name: editedItem.name,
                            password: pwd,
                            gender: editedItem.gender,
                            phone: editedItem.phone,
                            email: editedItem.email,
                            role: editedItem.role,
                            due_date: editedItem.due_date,
                            birthday: "",
                            address: editedItem.address,
                            bank_code: editedItem.bank_code,
                            bank_account: editedItem.bank_account,
                            identity: editedItem.identity,
                        },
                        this.config
                    )
                    .then((response) => {
                        console.log(response);
                        this.repsMessage = response.data.message;
                        const code = response.data.code;
                        const errorCode = response.data.error_code
                        console.log(this.repsMessage);
                        console.log(code);
                        console.log(errorCode);

                        if (errorCode === '') {
                            this.$swal(
                                "",
                                "成功更新 " + editedItem.name + " 資訊。",
                                "success"
                            );

                            this.close();

                            setTimeout(() => {
                                this.$router.go();
                            }, this.timeout)
                        } else {
                            this.alertError = true
                        }

                    })
                    .catch(function(error) {
                        // 请求失败处理
                        console.log(error);
                    });
            }

            if (this.editedIndex > -1) {
                this.editedItem.password = "";
                Object.assign(this.users[this.editedIndex], this.editedItem);
            } else {
                this.users.push(this.editedItem);
            }

            //this.close();
        },

        changeRole(editedItem) {
            console.log(editedItem.role);
            if (editedItem.role === "經銷商" || editedItem.role === "團購主") {
                if (
                    editedItem.due_date === "" ||
                    editedItem.address === "" ||
                    editedItem.bank_code === "" ||
                    editedItem.bank_code === 0 ||
                    editedItem.bank_account === "" ||
                    editedItem.bank_account === 0 ||
                    editedItem.identity === ""
                ) {
                    //editedItem.role = "會員";
                    this.$swal("", "轉經銷商或團購主，請填入完整資訊。", "error");
                }
            }
        },

        searchesMemberByType(type) {
            let data = {
                type: type,
            };

            axios
                .get("/v1/member", { params: data }, this.config)
                .then((response) => {
                    this.repsData = response.data;
                    console.log(this.repsData);

                    this.users = this.repsData.list;
                    console.log(this.users);

                    this.allNum = this.repsData.all_num;
                    this.dealerNum = this.repsData.dealer_num;
                    this.groupsNum = this.repsData.groups_num;
                    this.expiringDealerNum = this.repsData.expiring_dealer_num;
                    this.dueDateDealerNum = this.repsData.due_date_dealer_num;
                    this.memberNum = this.repsData.member_num;
                })
                .catch((error) => {
                    // 请求失败处理
                    console.log(error);
                });
        },

        goToThisMemberBonus(id) {
            console.log(id);
            setTimeout(() => {
                this.$router.push("/pages/applications/bonus?s=u" + id.toString());
            }, this.timeout);
        },

        goToThisMember(id) {
            console.log(id);

            const admin_token = localStorage.getItem("admin_token");
            console.log(admin_token);

            if (admin_token === null) {
                const token = Cookie.get("auth");
                localStorage.setItem("admin_token", token);
            }

            axios
                .post(
                    "/v1/fast-login", {
                        user_id: id,
                    },
                    this.config
                )
                .then((response) => {
                    console.log(response);
                    this.repsMessage = response.data.message;
                    const errorCode = response.data.error_code;
                    console.log(this.repsMessage);
                    console.log(errorCode);

                    if (errorCode === "") {
                        this.alertSuccess = true;
                        this.auth = response.data.token;
                        this.$store.commit("setAuth", this.auth);
                        Cookie.set("auth", null);
                        Cookie.set("auth", this.auth);
                        console.log(this.auth);

                        var tokenParts = this.auth.split(".");
                        var body = JSON.parse(atob(tokenParts[1]));
                        console.log("token body");
                        console.log(body);
                        console.log(body.sub);

                        this.userId = body.sub;
                        console.log(this.userId);

                        setTimeout(() => {
                            this.$router.push("/pages/pages/profile/overview");
                        }, this.timeout);
                    } else {
                        this.alertError = true;
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "新增會員資訊" : "修改會員資訊";
        },
        pages() {
            return this.pagination.rowsPerPage ?
                Math.ceil(this.items.length / this.pagination.rowsPerPage) :
                0;
        },
    },
};
</script>