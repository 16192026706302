<template>
    <v-card class="card-shadow border-radius-xl mt-6" id="change">
        <div class="px-6 py-6">
            <h5 class="text-h5 font-weight-bold text-typo">經銷會員查詢</h5>
        </div>
        <div class="px-6 pb-6 pt-0">
            <v-row>
                <v-col cols="12">
                    <!--<v-text-field v-model="current_pwd" label="目前密碼" color="#e91e63" outlined dense class="font-size-input input-style py-0"></v-text-field>-->
                    <v-text-field v-model="nameOrPhoneOrEmail" label="姓名、行動電話、電子信箱" @keydown.enter="searchDealers" color="#e91e63" outlined dense class="font-size-input input-style py-0"></v-text-field>
                </v-col>
            </v-row>
            <div class="d-sm-flex">
                <v-btn color="#cb0c9f" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-4
            px-8
            ms-auto
            mt-sm-auto mt-4
          " small @click="searchDealers">
                    查詢
                </v-btn>
            </div>
        </div>
    </v-card>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';

Vue.use(VueSweetalert2);

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    name: "search-dealer",
    data() {
        return {
            nameOrPhoneOrEmail: "",
            userId: 1,

        };
    },
    mounted() {
        const token = Cookie.get('auth')

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

    },
    methods: {
        searchDealers() {
            let data = {
                'nameOrPhoneOrEmail': this.nameOrPhoneOrEmail,
            };

            const resp = axios.get('/v1/dealer/' + this.userId.toString(), {
                    params: data
                }, this.config)
                .then(response => {
                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.code
                    const is_signup = response.data.is_signup
                    console.log(this.repsMessage)
                    console.log(errorCode)
                    console.log(is_signup)

                    if (errorCode === 0) {
                        if (is_signup === 0) {
                            this.$swal("尚未註冊", "無數據。", "info");

                        } else {
                            const name = response.data.name
                            const phone = response.data.phone
                            const email = response.data.email
                            const parent_name = response.data.parent_name
                            this.$swal("已註冊", "上層會員姓名：" + parent_name + "<br/>會員姓名：" + name + "<br/>行動電話：" + phone + "<br/>電子郵件：" + email, "info");
                        }
                    } else {
                        this.$swal("", this.repsMessage, "error");
                    }

                })
                .catch(function(error) { // 请求失败处理
                    console.log(error);
                });


        },


    },
};
</script>