<template>
    <div>
        <v-container fluid class="py-6">
            <div class="d-sm-flex justify-between">
                <!--<v-btn
          elevation="0"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-primary
            py-2
            px-6
            me-2
            mb-sm-0 mb-2
          "
          color="#5e72e4"
          small
          >New order</v-btn
        >-->
                <div class="d-flex ms-auto">
                    <!--<template>
                        <v-file-input accept=".xlsx" height="43" label="File input(xlsx)" v-model="selectXlsx" class="px-2" hidden outlined counter show-size small>
                        </v-file-input>
                    </template>-->
                    <!--<v-menu transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="0"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default btn-outline-default
                  shadow-none
                  py-2
                  px-6
                  me-2
                "
                color="transparent"
                small
              >
                Filters
                <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0">
              <v-list-item class="list-item-hover-active py-2">
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    Status: Paid
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="list-item-hover-active pb-2">
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    Status: Refunded
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="list-item-hover-active pb-2">
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    Status: Canceled
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr class="horizontal dark" />
              <v-list-item class="list-item-hover-active py-2">
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-danger ls-0 font-weight-600 mb-0"
                  >
                    Remove Filter
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>-->
                    <input ref="file" type="file" class="d-none" @change="uploadXlsx">
                    <v-btn elevation="0" height="43" class="
              font-weight-bold
              text-uppercase
              btn-default btn-outline-default
              shadow-none
              py-2
              px-6
              me-2
            " color="transparent" @click="$refs.file.click()" small>
                        <v-icon>ni ni-archive-2 me-1</v-icon>
                        Excel 匯入
                    </v-btn>
                    <v-btn elevation="0" height="43" class="
              font-weight-bold
              text-uppercase
              btn-default btn-outline-default
              shadow-none
              py-2
              px-6
              me-2
            " color="transparent" small @click="excelParser(reports)">
                        <v-icon>ni ni-archive-2 me-1</v-icon>
                        Excel 匯出
                    </v-btn>
                </div>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-card class="card-shadow border-radius-xl">
                        <table-orders @value-reports="getValFromChild"></table-orders>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import TableOrders from "./Widgets/TableOrders";
//import reports from "./Widgets/Data";
import exportFromJSON from "export-from-json";
import Cookie from 'js-cookie';
import axios from 'axios';
//import XLSX from "xlsx";
import * as XLSX from 'xlsx'

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

export default {
    name: "Orders-List",
    components: {
        TableOrders,
    },
    data() {
        return {
            reports: [],
            selectXlsx: null,
            code: 0,
            file: null,
            timeout: 1000,
        };
    },
    mounted() {
        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof token)
        console.log((token === 'null') || (typeof(token) === "undefined"))
        if ((token === 'null') || (typeof(token) === "undefined")) {
            this.$router.push('/pages/authentication/signup/login');
        }

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        /*
        axios.get('/v1/bonus-report', this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.reports = this.repsData.list
                console.log(this.reports)



            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });
            */



    },
    methods: {
        getValFromChild(val) {
            this.reports = val;
        },
        excelParser(data) {
            /*
            console.log(data)
            try {
                const fileName = "exported-data";
                const exportType = "xls";
                exportFromJSON({ data, fileName, exportType });
            } catch (e) {
                throw new Error("Parsing failed!");
            }
            */
            const xlsxHeader = ["id", "name", "transaction_date", "bonus", "fee", "bank_account", "bank_code", "point"]

            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.json_to_sheet(data, { header: xlsxHeader }, );
            XLSX.utils.book_append_sheet(wb, ws, 'bonus_month');
            XLSX.writeFile(wb, 'exported-data.xlsx');
        },


        uploadXlsx() {
            this.file = this.$refs.file.files[0];
            console.log(this.file)
            if (!this.file) {
                console.log("Please upload a xlsx file")
                return;
            }
            if (this.file) {
                const reader = new FileReader();
                //reader.readAsText(this.selectXlsx, 'UTF-8');
                reader.onload = (e) => {
                    console.log(e)

                    const bstr = e.target.result;
                    console.log(bstr)
                    const wb = XLSX.read(bstr, {
                        type: 'binary',
                        codepage: 936
                    });
                    console.log(wb)

                    const wsname = wb.SheetNames[0];
                    console.log(wsname)
                    const ws = wb.Sheets[wsname];
                    console.log(ws)

                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                    console.log("data");
                    console.log(data);
                    console.log(data[1][2]);
                    console.log(data[1].length)

                    for (let i = 1; i < data.length; i++) {
                        if (data[i].length === 8) {
                            axios.put('/v1/bonus-report', {
                                    id: data[i][0],
                                    transaction_time: data[i][2],
                                    point: 0,
                                    bonus: 0,
                                    bank_code: 0,
                                    bank_account: 0,
                                    fee: 0,
                                    type: 'month',
                                }, this.config)
                                .then(response => {
                                    console.log(response)
                                    this.repsMessage = response.data.message
                                    this.code = response.data.code
                                    const errorCode = response.data.code
                                    console.log(this.repsMessage)
                                    console.log(errorCode)

                                    if (this.code === 0) {
                                        //this.$swal("", "成功更新轉帳資訊。", "success");
                                        setTimeout(() => { // we simulate the async request with timeout.
                                            this.$router.go();
                                        }, this.timeout)
                                    } else {
                                        this.$swal("", "更新轉帳資訊失敗。", "error");
                                    }

                                })
                                .catch(function(error) { // 请求失败处理
                                    console.log(error);
                                });
                        }

                    }
                };

                reader.readAsBinaryString(this.file);
            }
            this.file = null;


        },




        /*
        uploadXlsx(event) {
            this.selectXlsx = event.target.files ? event.target.files[0] : null;
            if (this.selectXlsx) {
                const reader = new filereader();

                reader.onload = (e) => {
                    
                    const bstr = e.target.result;
                    const wb = xlsx.read(bstr, { type: 'binary' });
                    
                    const wsname = wb.sheetnames[0];
                    const ws = wb.sheets[wsname];
                    
                    const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
                }

                reader.readasbinarystring(this.selectXlsx);
            }
        },
        */

    },
};
</script>