<template>
  <v-container class="py-3 px-0">
    <div class="position-relative"
      :style="`background-image:  url(${require('@/assets/img/member-bg.jpg')}); background-size: cover; background-position: 50%;`">
      <span class="mask bg-gradient-default opacity-1"></span>
      <v-row class="text-center">
        <v-col cols="12" class="pt-6 pb-0">
          <v-avatar width="74" height="74" class="shadow rounded-circle">
            <img :src="line_picture_link" alt="Avatar" class="rounded-circle" />
          </v-avatar>
        </v-col>
        <v-col cols="12" class="pt-0">
          <div class="h-100">
            <h5 class="mb-2 text-h5 text-typo font-weight-bold">
              {{ name }}
            </h5>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col class="mx-3">
        <basic-info></basic-info>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BasicInfo from "./Widgets/BasicInfo.vue";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": "application/json;",
  }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


export default {
  name: "ProfileOverview",
  components: {
    BasicInfo,
  },
  data() {
    return {
      switche: true,
      name: "",
      role: "",
      line_picture_link: "",
    };
  },
  mounted() {
    const token = Cookie.get('auth')

    console.log(token)
    console.log(typeof token)
    console.log((token === 'null') || (typeof (token) === "undefined"))
    if ((token === 'null') || (typeof (token) === "undefined")) {
      this.$router.push('/login');
    }

    const tokenParts = token.split('.')
    console.log(tokenParts)
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    axios.get('/v1/user/' + this.userId.toString(), this.config)
      .then(response => {
        this.repsData = response.data;
        console.log(this.repsData)

        this.name = this.repsData.name
        this.email = this.repsData.email
        this.role = this.repsData.role
        this.line_picture_link = this.repsData.line_picture_link

      })
      .catch(error => { // 请求失败处理
        console.log(error);
      });
  },
};
</script>