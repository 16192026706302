<template>
    <v-card class="card-shadow border-radius-xl mt-3" id="basic">
        <div class="px-3 pb-3 pt-3">
            <v-row no-gutters>
                <v-col cols="3" class="d-flex align-center justify-start">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold">行動電話</label>
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-start">
                    <v-text-field v-model="phone" color="#e91e63" placeholder="0955168168"
                        :readonly="verifyPhoneBtnStatus" class="mt-0 pt-0 font-size-input input-style">
                        <template slot="append">
                            <v-btn :disabled="verifyPhoneBtnStatus" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            ms-auto
            mt-sm-auto
            mb-1
          " small @click="verifyPhone">
                                <span v-if="!phone_pass_status" class="text-white">
                                    驗證
                                </span>
                                <span v-if="phone_pass_status" class="text-white">
                                    已驗證
                                </span>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="3" class="d-flex align-center justify-start my-5">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold">LINE通知：</label>
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-start">
                    <v-sheet><span v-if="!line_notify_token" class="text-red  font-weight-bold ms-sm-2">
                            (未綁定)
                        </span>
                        <span v-if="line_notify_token" class="text-green font-weight-bold ms-sm-2">
                            (已綁定)
                        </span>
                    </v-sheet>
                    <v-sheet>
                        <v-btn v-if="!line_notify_token" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            ms-auto
            mt-sm-auto
            mx-1
            align-center
          " small @click="bindingLineNotify">
                            綁定
                        </v-btn>
                    </v-sheet>
                </v-col>

                <v-col cols="3" class="d-flex align-center justify-start">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">電子郵件</label>
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-start">
                    <v-text-field v-model="email" color="#e91e63" placeholder="xyz@gmail.com" readonly
                        class="mt-0 pt-0 font-size-input input-style align-center">
                    </v-text-field>
                </v-col>

                <v-col cols="3" class="d-flex align-center justify-start">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">生日</label>
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-start">
                    <v-text-field v-model="birthday" color="#e91e63" placeholder="yyyy-mm-dd e.g. 1990-01-01"
                        class="mt-0 pt-0 font-size-input input-style align-center">
                    </v-text-field>
                </v-col>

                <v-col cols="3" class="d-flex align-center justify-start">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">性別</label>
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-start">
                    <v-select v-model="gender" :items="genders" :value="gender" color="#e91e63"
                        class="mt-0 pt-0 font-size-input input-style align-center" single-line height="36"
                        @change="selectGender">
                    </v-select>
                </v-col>

                <v-col cols="3" class="d-flex align-center justify-start">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">地址</label>
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-start">
                    <v-text-field v-model="address" color="#e91e63" placeholder="高雄市鳳山區四海街88號"
                        class="mt-0 pt-0 font-size-input input-style align-center">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="start">
                <v-col cols="12" class="text-center">
                    <v-btn color="#cb0c9f" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-4
            px-8
            ms-auto
            mt-sm-auto mt-4
          " small @click="showUpdateSuccessAlert">
                        確認
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-card>

</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';
import { userDataUpdate, userData } from "@/apis/userService";

// yourURI 請設置為實際Line developer 設定的重新導向網址
const redirect_uri = process.env.VUE_APP_REDIRECT_URI;
const line_notify_client_id = "JzeUPDYqbK770jo6ngZ8MQ";

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

(async () => {
    try {
        const token = Cookie.get("auth");
        let userId = 1

        if (token === "null" || typeof token === "undefined") {
            console.log(token);
        } else {
            const tokenParts = token.split(".");
            console.log(tokenParts);
            const body = JSON.parse(atob(tokenParts[1]));
            console.log(body);
            console.log(body.sub);
            userId = body.sub
            console.log(userId)
        }

        // 使用 window.location.search 和 urlParams 獲取當前網頁 URL 中的查詢參數
        //const params = window.location.searchParams;
        const params = new URL(location.href).searchParams;
        console.log("params");
        console.log(params);

        const code = params.get("code");
        console.log(code);

        const state = params.get("state");
        console.log(state);

        const qs = require("qs");

        userData(userId)
            .then(response => {
                const repsData = response.data;
                console.log(repsData)

                const fullName = repsData.name
                const address = repsData.address
                const gender = repsData.gender
                const phone = repsData.phone
                const email = repsData.email
                const email_pass_status = repsData.email_pass_status
                const phone_pass_status = repsData.phone_pass_status
                const role = repsData.role
                const due_date = repsData.due_date
                const birthday = repsData.birthday
                const bank_code = repsData.bank_code
                const bank_account = repsData.bank_account
                const identity = repsData.identity

                userDataUpdate({
                    name: fullName,
                    gender: gender,
                    phone: phone,
                    email: email,
                    role: role,
                    due_date: due_date,
                    birthday: birthday,
                    address: address,
                    bank_code: bank_code,
                    bank_account: bank_account,
                    identity: identity,
                    password: "",
                    code: code,
                }, userId)
                    .then(response => {
                        console.log(response)
                        const repsMessage = response.data.message
                        const errorCode = response.data.code
                        console.log(repsMessage)
                        console.log(errorCode)
                    })
                    .catch(function (error) { // 请求失败处理
                        console.log(error);
                    });

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });

    } catch (error) {
        console.log(error);
    }
})();


export default {
    name: "basic-info",
    data() {
        return {
            userId: 1,
            name: "",
            address: "",
            genders: ["", "男性", "女性"],
            gender: "",
            genderInt: 0,
            months: ["一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
            ],
            days: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            years: ["2021", "2020", "2019", "2018", "2017"],
            menuStart: false,
            email: "",
            phone: "",
            line_notify_token: "",
            phone_pass_status: 0,
            birthday: "",
        };
    },
    computed: {
        verifyPhoneBtnStatus() {
            return this.phone_pass_status === 1 ? true : false;
        },
        changeDataTypeGender() {
            return this.gender === "女性" ? 2 : 1;
        },
    },
    mounted() {
        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof token)
        console.log((token === 'null') || (typeof (token) === "undefined"))
        if ((token === 'null') || (typeof (token) === "undefined") || (token === "")) {
            console.log('token is null')
            console.log(token)
        }

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        setTimeout(() => {
            userData(this.userId)
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)

                    this.name = this.repsData.name
                    this.address = this.repsData.address
                    //this.gender = this.repsData.gender === 2 ? "女性" : "男性" //this.genders[this.repsData.gender - 1]
                    if (this.repsData.gender === "") {
                        this.gender = ""
                    } else {
                        this.gender = this.repsData.gender === 2 ? "女性" : "男性"
                    }
                    this.phone = this.repsData.phone
                    this.email = this.repsData.email
                    this.email_pass_status = this.repsData.email_pass_status
                    this.phone_pass_status = this.repsData.phone_pass_status
                    this.role = this.repsData.role
                    this.due_date = this.repsData.due_date
                    this.birthday = this.repsData.birthday
                    this.bank_code = this.repsData.bank_code
                    this.bank_account = this.repsData.bank_account
                    this.identity = this.repsData.identity
                    this.line_notify_token = this.repsData.line_notify_token

                    if (this.phone === "" || this.phone_pass_status === 0 || this.birthday === "" || this.gender === "" || this.address === "" || this.line_notify_token === "") {
                        this.$swal("請正確填寫會員資訊、驗證及綁定LINE通知", "會員資訊填寫完整即可後續服務的操作。", "warning");
                    } else {
                        this.$router.push('/homepage');
                    }
                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });
        }, 1000);
    },
    methods: {
        selectGender(e) {

            console.log("phoneOtp")
            console.log(this.phoneOtp)
            if (e === "男性") {
                this.genderInt = 1
            } else if (e === "女性") {
                this.genderInt = 2
            } else {
                this.genderInt = 1
            }
        },
        showUpdateSuccessAlert() {
            userDataUpdate({
                name: this.name,
                gender: this.changeDataTypeGender,
                phone: this.phone,
                email: this.email,
                role: this.role,
                due_date: this.due_date,
                birthday: this.birthday,
                address: this.address,
                bank_code: this.bank_code,
                bank_account: this.bank_account,
                identity: this.identity,
                password: "",
                code: "",
            }, this.userId)
                .then(response => {
                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.code
                    console.log(this.repsMessage)
                    console.log(errorCode)
                    if (errorCode === 0) {
                        this.$swal({
                            title: "",
                            text: "成功更新個人資訊。",
                            icon: "success",
                            confirmButtonText: "確認",
                            customClass: {
                                confirmButton: "btn bg-gradient-success",
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 100);
                            }
                        });
                    } else {
                        this.$swal({
                            title: "",
                            text: this.repsMessage,
                            icon: "error",
                            confirmButtonText: "確認",
                            customClass: {
                                confirmButton: "btn bg-gradient-success",
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 100);
                            }
                        });
                    }
                })
                .catch(function (error) { // 请求失败处理
                    console.log(error);
                });

        },
        showUpdatePwdSuccessAlert() {
            this.$swal("", "成功變更密碼。", "success");
        },
        bindingLineNotify() {
            let link = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${line_notify_client_id}&redirect_uri=${redirect_uri}&scope=notify&state=abcd16888`; //自定義String防止CSRF攻擊

            // 將頁面重新導向到該連結
            window.location.href = link;
        },
        verifyPhone() {
            userDataUpdate({
                name: this.name,
                gender: this.gender,
                phone: this.phone,
                email: this.email,
                role: this.role,
                due_date: this.due_date,
                birthday: this.birthday,
                address: this.address,
                bank_code: this.bank_code,
                bank_account: this.bank_account,
                identity: this.identity,
                password: "",
                code: "",
            }, this.userId)
                .then(response => {
                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.code
                    console.log(this.repsMessage)
                    console.log(errorCode)
                })
                .catch(function (error) { // 请求失败处理
                    console.log(error);
                });

            let resp = axios.post('/v1/verify/phone/' + this.userId.toString(), this.config)
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)
                    const errorCode = response.data.error_code
                    const message = response.data.message

                    if (errorCode === '') {
                        this.$swal("", "已傳送驗證連結至行動電話", "success");
                    } else {
                        this.$swal("", message, "error");
                    }
                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>