<template>
  <v-row class="align-center d-flex h-100">
    <v-col lg="4" md="7" class="mx-auto">
      <v-card class="card-shadow border-radius-xl py-1">
        <div class="
            card-padding
            text-center
            mt-n7
            bg-gradient-success
            shadow-success
            border-radius-lg
            mx-4
          ">
          <h4 class="text-h4 text-white font-weight-bold">快速註冊及登入</h4>
          <v-row class="mt-1">
            <v-col cols="12" class="me-auto d-flex justify-center">
              <v-btn icon outlined rounded color="transparent" class="text-center border-radius-md" @click="lineSignUp">
                <i class="fab fa-line text-white fa-lg" style="font-size:4em"></i>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="mt-2 position-relative text-center">
          <p class="
              text-sm
              font-weight-bold
              mb-2
              text-secondary text-border
              d-inline
              z-index-2
              bg-white
              px-3
            ">
            或
          </p>
        </div>
        <div class="text-center">
          <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="blue"
            content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{ repsMessage
            }}</v-snackbar>
          <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="blue"
            content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{ repsMessage
            }}</v-snackbar>
        </div>
        <div class="card-padding">
          <v-text-field label="姓名" placeholder="Name" v-model="name" color="#e91e63" required
            class="font-size-input input-style"></v-text-field>

          <v-text-field label="電子郵件" placeholder="Email" v-model="email" color="#e91e63" required
            class="font-size-input input-style"></v-text-field>

          <!--<v-text-field
            label="電話"
            placeholder="Phone"
            v-model="phone"
            color="#e91e63"
            required
            class="font-size-input input-style"
          ></v-text-field>-->

          <v-text-field label="密碼" placeholder="Password" v-model="password" color="#e91e63" type="password"
            class="font-size-input input-style"></v-text-field>

          <v-checkbox v-model="checkbox" color="#141727" :ripple="false" class="ma-0 checkbox-custom checkbox-thinner"
            hide-details>
            <template v-slot:label>
              <span class="text-body text-sm ls-0">我同意
                <a href="javascript:;" class="text-dark font-weight-bolder text-decoration-none">服務條款和條件</a></span>
            </template>
          </v-checkbox>

          <v-btn elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            " color="#5e72e4" small @click="postSignUp">註冊</v-btn>
          <p class="text-sm text-body mt-3 mb-0">
            已經有帳號了嗎？
            <a href="javascript:;" class="text-dark text-decoration-none font-weight-bolder" @click="goToLogin">登入</a>
          </p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from "js-cookie";
import axios from "axios";
import { userSignUp, userLogin } from "@/apis/userService";

Vue.use(VueSweetalert2);

const client_id = "2005673202"; // yourClient_id 請設置為實際Channel ID 值
const redirect_uri = "https://so.zyt05.net"; // yourURI 請設置為實際Line developer 設定的重新導向網址
const bot_prompt = "normal";
const client_secret = "b20be1465398844207a50ffd27a041e0"; // yourClient_secret 請設置為實際Channel Secret 值

export default {
  name: "sign-up",
  data() {
    return {
      checkbox: false,
      name: "",
      password: "",
      email: "",
      phone: "",
      dateBirthday: new Date(1981, 1, 1, 0, 0, 0), //Fri Apr 15 1988 10:22:15,
      alertSuccess: false,
      alertError: false,
      repsMessage: "",
      timeout: 1000 * 3,
    };
  },
  methods: {
    lineSignUp() {
      // 根據指定的 client_id、redirect_uri、scope 等參數組合出一個 LINE 登入的連結
      let link = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=login&bot_prompt=aggressive&friendship_status_changed=true&scope=profile%20openid%20email`;
      // 將頁面重新導向到該連結
      window.location.href = link;
    },
    postSignUp() {
      if (this.checkbox) {
        userSignUp({
          parent_id: 1,
          name: this.name,
          password: this.password,
          gender: "",
          phone: this.phone,
          email: this.email,
          dealer_number: "3",
          birthday: this.dateBirthday,
          address: "",
          bank_code: 0,
          bank_account: 0,
          identity: "",
          signup_type: "",
          line_uid: "",
        })
          .then((response) => {
            console.log(response);
            this.repsMessage = response.data.message;
            const errorCode = response.data.error_code;
            console.log("response message.");
            console.log(this.repsMessage);
            console.log(errorCode);

            if (errorCode === "") {
              userLogin({
                email: this.email,
                password: this.password,
                login_type: "",
              })
                .then((response) => {
                  console.log(response);
                  this.repsMessage = response.data.message;
                  const errorCode = response.data.error_code;
                  console.log(this.repsMessage);
                  console.log(errorCode);

                  if (errorCode === "") {
                    this.alertSuccess = true;
                    this.auth = response.data.token;
                    //this.$store.commit('setAuth', this.auth);
                    Cookie.set("auth", this.auth);
                    console.log(this.auth);

                    var tokenParts = this.auth.split(".");
                    var body = JSON.parse(atob(tokenParts[1]));
                    console.log("token body");
                    console.log(body);
                    console.log(body.sub);

                    this.userId = body.sub;
                    console.log(this.userId);

                    setTimeout(() => {
                      this.$router.push("/profile");
                    }, this.timeout);
                  } else {
                    this.alertError = true;
                  }
                })
                .catch(function (error) {
                  // 请求失败处理
                  console.log(error);
                });
            } else {
              this.alertError = true;
              setTimeout(() => {
                this.$router.go();
              }, this.timeout);
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error);
          });
      } else {
        this.alertError = true;
        this.repsMessage = "請勾選同意條款和條件";
      }
    },
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
