<template>
  <div class="pt-0 mt-0"
    :style="`background: url(${require('@/assets/img/order-settings.jpg')}) top center fixed; background-size: cover; height: 100vh;`">
    <v-layout row justify-center>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent
        :retain-focus="true">
        <v-card class="card-shadow border-radius-xl">
          <div class="pt-0 mt-0"
            :style="`background: url(${require('@/assets/img/disclaimer-subject.jpg')}) top center; background-size: cover; height: 100vh;`">
            <v-card-actions class="disclaimer-overlay">
              <v-row>
                <v-col cols="2" class="text-sm text-body text-center my-0 py-0">
                  <div>
                    <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="dialog = false">
                      不同意
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="8" class="text-sm text-body text-center my-0 py-0">
                </v-col>
                <v-col cols="2" class="text-sm text-body text-center my-0 py-0">
                  <div>
                    <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" color="primary" @click="startProcess">
                      同意
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="10" cols="12" class="mx-auto">
          <div class="text-center mt-5">
            <h4 v-if="isShowTitle" class="text-h4 text-typo font-weight-bold">
              新增賓果EX機器人
            </h4>
          </div>
          <v-card v-if="isShowStepperStepCard" class="shadow border-radius-xl mx-auto">
            <v-stepper mobile elevation="0" v-model="e1" class="bg-transparent">
              <v-row>
                <v-col>
                  <v-stepper-header class="
                      shadow-primary
                      border-radius-lg
                    " style="background:#7986CB;">
                    <v-stepper-step step="1" color="#344767">
                      <span class="
                          opacity-7
                          text-xl
                          font-weight-normal
                          text-uppercase
                        ">娛樂城選擇</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="2" color="#344767">
                      <span class="
                          text-white
                          opacity-7
                          text-xl
                          font-weight-normal
                          text-uppercase
                        ">超獎猜，單</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3" color="#344767">
                      <span class="
                          text-white
                          opacity-7
                          text-xs
                          font-weight-normal
                          text-uppercase
                        ">超獎猜，雙</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="4" color="#344767">
                      <span class="
                          text-white
                          opacity-7
                          text-xl
                          font-weight-normal
                          text-uppercase
                        ">超獎猜，大</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="5" color="#344767">
                      <span class="
                          text-white
                          opacity-7
                          text-xl
                          font-weight-normal
                          text-uppercase
                        ">超獎猜，小</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="6" color="#344767">
                      <span class="
                          text-white
                          opacity-7
                          text-xl
                          font-weight-normal
                          text-uppercase
                        ">超獎猜，隨機</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="7" color="#344767">
                      <span class="
                          text-white
                          opacity-7
                          text-xl
                          font-weight-normal
                          text-uppercase
                        ">贏停損停</span>
                    </v-stepper-step>
                  </v-stepper-header>
                </v-col>
              </v-row>
            </v-stepper>
          </v-card>

          <v-card class="shadow border-radius-xl mt-2 mx-auto">
            <v-stepper mobile elevation="0" v-model="e1" class="bg-transparent overflow-visible">
              <v-row no-gutters>
                <v-col cols="12" class="mx-auto">
                  <v-stepper-items class="border-radius-xl overflow-hidden">
                    <v-stepper-content step="1" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            娛樂城選擇
                          </h5>
                          <hr class="horizontal mb-4 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-select v-model="betting" :items="bettings" :value="betting" :rules="requireRules"
                                color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  align-center
                                " single-line height="38" @change="selectBetting">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">娛樂城</label>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-text-field v-model="account" placeholder="simonhung" :rules="requireRules"
                                color="#e91e63" required class="font-size-input input-style">
                                <template slot="prepend">
                                  <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">帳號</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col sm="6" cols="12">
                              <v-text-field v-model="password" placeholder="abcd1234" :rules="requireRules"
                                color="#e91e63" required class="font-size-input input-style">
                                <template slot="prepend">
                                  <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">密碼</label>
                                </template></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="text-end">
                          <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              btn-default
                              text-xs
                              py-5
                              px-6
                              mt-6
                              mb-2
                              me-2
                            " style="background-color: #1A237E;color: white" @click="step1Next">
                            下一步
                          </v-btn>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            超獎猜，單
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-select v-model="bet_type_odd" :items="bet_types" :value="bet_type_odd"
                                :rules="requireRules" color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectBetTypeOdd">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">選擇下注法</label>
                                </template>
                              </v-select>
                              <p v-if="bet_type_odd_ao_chu" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ao_chu_desc }}
                              </p>
                              <p v-if="bet_type_odd_ciang_gong" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ciang_gong_desc }}</p>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="txt_odd_exeucte_num_of_pass" placeholder="1~10" color="#e91e63" type="number"
                                :rules="exeucteNumOfPassRules" required
                                class="font-size-input input-style align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_1" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第一關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_6" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第六關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_2" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第二關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_7" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第七關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_3" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第三關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_8" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第八關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_4" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第四關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_9" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第九關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_5" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第五關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_odd_10" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第十關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="7">
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox v-model="chk_odd_disable" color="#5e72e4" :ripple="false"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">不啟動此設定</span>
                                </template>
                              </v-checkbox>
                            </v-col>

                            <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                              <div class="d-flex">
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="e1 = 1">
                                  上一步
                                </v-btn>
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" @click="step2Next">
                                  下一步
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            超獎猜，雙
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-select v-model="bet_type_even" :items="bet_types" :value="bet_type_even"
                                :rules="requireRules" color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectBetTypeEven">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">選擇下注法</label>
                                </template>
                              </v-select>
                              <p v-if="bet_type_even_ao_chu" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ao_chu_desc }}
                              </p>
                              <p v-if="bet_type_even_ciang_gong" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ciang_gong_desc }}</p>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="txt_even_exeucte_num_of_pass" placeholder="1~10" color="#e91e63" type="number"
                                :rules="exeucteNumOfPassRules" required
                                class="font-size-input input-style align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_1" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第一關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_6" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第六關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_2" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第二關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_7" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第七關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_3" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第三關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_8" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第八關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_4" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第四關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_9" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第九關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_5" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第五關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_even_10" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第十關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="7">
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox v-model="chk_even_disable" color="#5e72e4" :ripple="false"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">不啟動此設定</span>
                                </template>
                              </v-checkbox>
                            </v-col>

                            <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                              <div class="d-flex">
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="e1 = 2">
                                  上一步
                                </v-btn>
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" color="primary" @click="step3Next">
                                  下一步
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>


                    <v-stepper-content step="4" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            超獎猜，大
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-select v-model="bet_type_big" :items="bet_types" :value="bet_type_big"
                                :rules="requireRules" color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectBetTypeBig">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">選擇下注法</label>
                                </template>
                              </v-select>
                              <p v-if="bet_type_big_ao_chu" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ao_chu_desc }}
                              </p>
                              <p v-if="bet_type_big_ciang_gong" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ciang_gong_desc }}</p>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="txt_big_exeucte_num_of_pass" placeholder="1~10" color="#e91e63" type="number"
                                :rules="exeucteNumOfPassRules" required
                                class="font-size-input input-style align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_1" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第一關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_6" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第六關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_2" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第二關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_7" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第七關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_3" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第三關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_8" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第八關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_4" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第四關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_9" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第九關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_5" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第五關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_big_10" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第十關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="7">
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox v-model="chk_big_disable" color="#5e72e4" :ripple="false"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">不啟動此設定</span>
                                </template>
                              </v-checkbox>
                            </v-col>

                            <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                              <div class="d-flex">
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="e1 = 3">
                                  上一步
                                </v-btn>
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" color="primary" @click="step4Next">
                                  下一步
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="5" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            超獎猜，小
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-select v-model="bet_type_small" :items="bet_types" :value="bet_type_small"
                                :rules="requireRules" color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectBetTypeSmall">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">選擇下注法</label>
                                </template>
                              </v-select>
                              <p v-if="bet_type_small_ao_chu" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ao_chu_desc }}
                              </p>
                              <p v-if="bet_type_small_ciang_gong" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ciang_gong_desc }}</p>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="txt_small_exeucte_num_of_pass" placeholder="1~10" color="#e91e63" type="number"
                                :rules="exeucteNumOfPassRules" required
                                class="font-size-input input-style align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_1" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第一關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_6" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第六關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_2" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第二關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_7" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第七關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_3" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第三關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_8" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第八關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_4" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第四關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_9" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第九關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_5" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第五關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_small_10" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第十關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="7">
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox v-model="chk_small_disable" color="#5e72e4" :ripple="false"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">不啟動此設定</span>
                                </template>
                              </v-checkbox>
                            </v-col>

                            <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                              <div class="d-flex">
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="e1 = 4">
                                  上一步
                                </v-btn>
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" @click="step5Next">
                                  下一步
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>


                    <v-stepper-content step="6" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            超獎猜，隨機
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-select v-model="random_type" :items="random_types" :value="random_type"
                                :rules="requireRules" color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectRandomType">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">隨機選擇</label>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col sm="6" cols="12">
                              <v-select v-model="bet_type_random" :items="bet_types" :value="bet_type_random"
                                :rules="requireRules" color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectBetTypeRandom">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">選擇下注法</label>
                                </template>
                              </v-select>
                              <p v-if="bet_type_random_ao_chu" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ao_chu_desc }}
                              </p>
                              <p v-if="bet_type_random_ciang_gong" class="my-0 text-sm text-body font-weight-light">
                                {{ bet_type_ciang_gong_desc }}</p>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="txt_random_exeucte_num_of_pass" placeholder="1~10" color="#e91e63" type="number"
                                :rules="exeucteNumOfPassRules" required
                                class="font-size-input input-style align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_1" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第一關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_6" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第六關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_2" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第二關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_7" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第七關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_3" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第三關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_8" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第八關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_4" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第四關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_9" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第九關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_5" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第五關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_random_10" placeholder="0" color="#e91e63" type="number"
                                :rules="notOrderAmountRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第十關</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="7">
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox v-model="chk_random_disable" color="#5e72e4" :ripple="false"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">不啟動此設定</span>
                                </template>
                              </v-checkbox>
                            </v-col>

                            <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                              <div class="d-flex">
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E;" @click="e1 = 5">
                                  上一步
                                </v-btn>
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white;" color="primary" @click="step6Next">
                                  下一步
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>


                    <v-stepper-content step="7" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            贏停損停
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <p class="mb-0 text-sm text-body font-weight-light">
                            當啟動後，將開始累積輸贏金額，達到或超過下列任一設定值，則機器人將停止自動下注。
                          </p>
                          <v-row no-gutters>
                            <v-col cols="11" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="stop_loss" placeholder="-9999999" color="#e91e63" type="number"
                                :rules="stopLossRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">損停</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="11" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="stop_win" placeholder="9999999" color="#e91e63" type="number"
                                :rules="stopWinRules" required
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">贏停</label>
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                              <div class="d-flex">
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E;" @click="e1 = 6">
                                  上一步
                                </v-btn>
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white;" color="primary" @click="openDisclaimer">
                                  啟動
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-col>
              </v-row>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-progress-circular v-if="isShowProgress" indeterminate color="#5E35B1" :size="70" :width="7"
            class="progress-overlay"></v-progress-circular>
          <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="#1A237E"
            content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
              repsMessage
            }}</v-snackbar>
          <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="#1A237E"
            content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
              repsMessage
            }}</v-snackbar>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import HtmlEditor from "./Widgets/HtmlEditor.vue";
import Dropzone from "./Widgets/Dropzone.vue";
import Cookie from "js-cookie";
import { gameSettingData, gameData } from "@/apis/gameService";
import { userData } from "@/apis/userService";
import { casinoData } from "@/apis/casinoService";
import g from "vue-marquee-text-component";

Vue.use(VueSweetalert2);

function checkBetTypeNotNull(betType) {
  var errorBetTypeCount = 0
  if ((betType === 'null') || (typeof (betType) === "undefined") || (betType === "")) {
    errorBetTypeCount = errorBetTypeCount + 1
  }
  return errorBetTypeCount
}

function checkAnyAmount(amountList) {
  var errorAmountCount = 0
  let amountListLen = amountList.length
  for (var i = 0; i < amountListLen; i++) {
    let intValue = parseInt(amountList[i]);

    if (intValue >= 1 && intValue <= 99) {
      errorAmountCount = errorAmountCount + 1
    }
  }
  return errorAmountCount
}

function checkAnyAmountIsNotZero(amountList) {
  var errorZeroAmountCount = 0
  var zeroAmountCount = 0
  let amountListLen = amountList.length
  for (var i = 0; i < amountListLen; i++) {
    let intValue = parseInt(amountList[i]);

    if (intValue === 0) {
      zeroAmountCount = zeroAmountCount + 1
    }
  }

  if (zeroAmountCount === amountListLen) {
    errorZeroAmountCount = 1
  }
  return errorZeroAmountCount
}

function checkAnyOrderTactic(orderTacticList) {
  var errorOrderTactic = false
  var orderTacticCount = 0
  let orderTacticListLen = orderTacticList.length
  for (var i = 0; i < orderTacticListLen; i++) {
    if (orderTacticList[i] === 1 || orderTacticList[i] === true) {
        orderTacticCount = orderTacticCount + 1
    }
  }
  if (orderTacticCount === orderTacticListLen) {
    errorOrderTactic = true
  }
  return errorOrderTactic
}

function checkExeucteNumOfPass(amountList, exeucteNumOfPass) {
  var errorCount = 0
  let intValue = parseInt(exeucteNumOfPass ? exeucteNumOfPass : 0);
  for (var i = 0; i < intValue; i++) {
    let amount = amountList[i]
    if ((amount === 'null') || (typeof (amount) === "undefined") || (amount === "")) {
      errorCount = errorCount + 1
    }
  }
  return errorCount
}


export default {
  components: {
    HtmlEditor,
    Dropzone,
  },
  data() {
    return {
      requireRules: [
        value => { //rule 1
          //console.log("requireRules")
          //console.log(value)
          if (value) return true

          return '必填欄位'
          /*
          if ((value === 'null') || (typeof (value) === "undefined") || (value === "")) {
            return '必填欄位'
          } else {
            return true
          }*/
        },
      ],
      notOrderAmountRules: [
        value => { //rule 2
          //console.log("notOrderAmountRules")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue >= 1 && intValue <= 99) {
            return '不能輸入1~99，該遊戲最小下注金額為100'
          }
        },
      ],
      exeucteNumOfPassRules: [
        value => { //rule 1
          //console.log("exeucteNumOfPassRules1")
          //console.log(value)
          if (value) return true

          return '必填欄位'
        },
        value => { //rule 2
          //console.log("exeucteNumOfPassRules2")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue < 1 || intValue > 10) {
            return '只能輸入1~10'
          }
        },
      ],
      stopWinRules: [
        value => { //rule 1
          //console.log("stopWinRules1")
          //console.log(value)
          if (value) return true

          return '必填欄位'
        },
        value => { //rule 2
          //console.log("stopWinRules2")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue <= 0) {
            return '不能小於等於 0'
          }
        },
      ],
      stopLossRules: [
        value => { //rule 1
          //console.log("stopLossRules1")
          //console.log(value)
          if (value) return true

          return '必填欄位'
        },
        value => { //rule 2
          //console.log("stopLossRules2")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue >= 0) {
            return '不能大於等於 0'
          }
        },
      ],
      e1: 1,
      userId: 1,
      bettings: [],
      bet_types: ["凹注", "強攻"],
      betting: "",
      account: "",
      password: "",
      bet_type: "凹注",
      bet_type_odd_even: "凹注",
      bet_type_big_small: "凹注",
      bet_type_odd: "凹注",
      bet_type_even: "凹注",
      bet_type_big: "凹注",
      bet_type_small: "凹注",
      bet_type_random: "凹注",
      random_types: ["單、雙，二選一", "大、小，二選一", "單、雙、大、小，四選一"],
      random_type: "",
      chk_odd_disable: 0,
      chk_even_disable: 0,
      chk_big_disable: 0,
      chk_small_disable: 0,
      chk_random_disable: 0,
      txt_odd_even_exeucte_num_of_pass: 1,
      txt_odd_exeucte_num_of_pass: 1,
      txt_even_exeucte_num_of_pass: 1,
      txt_odd_even_1: 0,
      txt_odd_even_2: 0,
      txt_odd_even_3: 0,
      txt_odd_even_4: 0,
      txt_odd_even_5: 0,
      txt_odd_even_6: 0,
      txt_odd_even_7: 0,
      txt_odd_even_8: 0,
      txt_odd_even_9: 0,
      txt_odd_even_10: 0,
      txt_odd_1: 0,
      txt_odd_2: 0,
      txt_odd_3: 0,
      txt_odd_4: 0,
      txt_odd_5: 0,
      txt_odd_6: 0,
      txt_odd_7: 0,
      txt_odd_8: 0,
      txt_odd_9: 0,
      txt_odd_10: 0,
      txt_even_1: 0,
      txt_even_2: 0,
      txt_even_3: 0,
      txt_even_4: 0,
      txt_even_5: 0,
      txt_even_6: 0,
      txt_even_7: 0,
      txt_even_8: 0,
      txt_even_9: 0,
      txt_even_10: 0,
      txt_big_small_exeucte_num_of_pass: 1,
      txt_big_exeucte_num_of_pass: 1,
      txt_small_exeucte_num_of_pass: 1,
      txt_big_small_1: 0,
      txt_big_small_2: 0,
      txt_big_small_3: 0,
      txt_big_small_4: 0,
      txt_big_small_5: 0,
      txt_big_small_6: 0,
      txt_big_small_7: 0,
      txt_big_small_8: 0,
      txt_big_small_9: 0,
      txt_big_small_10: 0,
      txt_big_1: 0,
      txt_big_2: 0,
      txt_big_3: 0,
      txt_big_4: 0,
      txt_big_5: 0,
      txt_big_6: 0,
      txt_big_7: 0,
      txt_big_8: 0,
      txt_big_9: 0,
      txt_big_10: 0,
      txt_small_1: 0,
      txt_small_2: 0,
      txt_small_3: 0,
      txt_small_4: 0,
      txt_small_5: 0,
      txt_small_6: 0,
      txt_small_7: 0,
      txt_small_8: 0,
      txt_small_9: 0,
      txt_small_10: 0,
      txt_random_exeucte_num_of_pass: 1,
      txt_random_1: 0,
      txt_random_2: 0,
      txt_random_3: 0,
      txt_random_4: 0,
      txt_random_5: 0,
      txt_random_6: 0,
      txt_random_7: 0,
      txt_random_8: 0,
      txt_random_9: 0,
      txt_random_10: 0,
      stop_win: 1000,
      stop_loss: -800,
      isShowProgress: false,
      alertSuccess: false,
      alertError: false,
      repsMessage: "",
      timeout: 1000 * 2, // 2秒
      dialog: false,
      bet_type_odd_even_ao_chu: false,
      bet_type_odd_even_ciang_gong: false,
      bet_type_big_small_ao_chu: false,
      bet_type_big_small_ciang_gong: false,
      bet_type_odd_ao_chu: false,
      bet_type_even_ao_chu: false,
      bet_type_odd_ciang_gong: false,
      bet_type_even_ciang_gong: false,
      bet_type_big_ao_chu: false,
      bet_type_small_ao_chu: false,
      bet_type_big_ciang_gong: false,
      bet_type_small_ciang_gong: false,
      bet_type_random_ao_chu: false,
      bet_type_random_ciang_gong: false,
      bet_type_ao_chu_desc: "凹注法意旨當下投注金額關數沒通過就往下一關投注金額，反之過關就回到第一關投注金額。",
      bet_type_ciang_gong_desc: "強攻法意旨當下投注金額關數過關就往下一關投注金額，反之沒過關就回到第一關投注金額。",
      isShowTitle: true,
      isShowStepperStepCard: false,
      sidebarTheme: "dark",
    };
  },
  /*
  computed: {
    bet_type_ao_chu: function () {
      console.log(this.bet_type)
      if (this.bet_type_odd_even === "凹注") {
        return true
      }
      return false
    },
    bet_type_ciang_gong: function () {
      console.log(this.bet_type)
      if (this.bet_type === "強攻") {
        return true
      }
      return false
    },

  },
  */
  watch: {
    e1: function () {
      if (this.e1 === 1) {
        this.isShowTitle = true
      } else {
        this.isShowTitle = false
      }

      setTimeout(() => {
        window.scrollTo(0, -100);
        //window.scrollTo({top: 0, behavior:"auto"})
        //window.scrollTo({top: 0, behavior:"smooth"})
      }, 50);
    }
  },
  mounted() {
    const token = Cookie.get('auth')

    console.log(token)
    console.log(typeof (token))
    console.log((token === 'null') || (typeof (token) === "undefined"))
    if ((token === 'null') || (typeof (token) === "undefined")) {
      this.$router.push('/login');
    }

    const tokenParts = token.split('.')
    console.log(tokenParts)
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    userData(this.userId)
      .then((response) => {
        console.log(response);
        this.repsData = response.data
        this.repsMessage = response.data.message;
        const errorCode = response.data.error_code;
        console.log(this.repsData);
        console.log(errorCode);

        let line_notify_token = this.repsData.line_notify_token
        console.log("line_notify_token");
        console.log(line_notify_token);
        if ((line_notify_token === 'null') || (typeof (line_notify_token) === "undefined") || (line_notify_token === "")) {
          this.$swal({
            title: '',
            text: '尚未綁定LINE通知',
            icon: 'error',
            confirmButtonText: '前往會員資訊綁定'
          }).then(
            function () {
              window.location.href = "/profile"
            });
        }
      })
      .catch(function (error) {
        // 請求失敗處理
        console.log(error);
      });

    const data = {
      'game_name': 'Bingo Ex',
    };
    casinoData(data)
      .then((response) => {
        this.repsData = response.data;
        console.log(this.repsData)
        this.bettings = this.repsData.casino_name_list

      })
      .catch(function (error) {
        // 請求失敗處理
        console.log(error);
      });
    
    this.$swal("", "啟動 Aiwin 自動下注機器人時，會使用您在賓果EX遊戲館和錢包裡的點數進行策略投注。請確認這些點數的餘額是否足夠。其他遊戲館的點數將不會被使用。", "warning");
  },
  methods: {
    step1Next() {
      var errorCount = 0

      let betting = this.betting
      if ((betting === 'null') || (typeof (betting) === "undefined") || (betting === "")) {
        errorCount = errorCount + 1
      }

      let account = this.account
      if ((account === 'null') || (typeof (account) === "undefined") || (account === "")) {
        errorCount = errorCount + 1
      }

      let password = this.password
      if ((password === 'null') || (typeof (password) === "undefined") || (password === "")) {
        errorCount = errorCount + 1
      }

      if (errorCount > 0) {
        this.alertError = true
        this.repsMessage = "請選擇或輸入必要欄位"
      }
      else {
        this.e1 = 2
      }
    },
    step2Next() {
      // check bet type
      var errorBetTypeCount = checkBetTypeNotNull(this.bet_type_odd)

      const odd_amount_list = [];
      odd_amount_list.push(this.txt_odd_1)
      odd_amount_list.push(this.txt_odd_2)
      odd_amount_list.push(this.txt_odd_3)
      odd_amount_list.push(this.txt_odd_4)
      odd_amount_list.push(this.txt_odd_5)
      odd_amount_list.push(this.txt_odd_6)
      odd_amount_list.push(this.txt_odd_7)
      odd_amount_list.push(this.txt_odd_8)
      odd_amount_list.push(this.txt_odd_9)
      odd_amount_list.push(this.txt_odd_10)

      // amount
      var errorAmountCount = checkAnyAmount(odd_amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(odd_amount_list)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(odd_amount_list, this.txt_odd_exeucte_num_of_pass)
      console.log(errorCount)

      if ((this.chk_odd_disable === 1) || this.chk_odd_disable === true) {
        this.e1 = 3
      } else {
        if (errorCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不符合"
        } else if (errorAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "不能輸入1~99，該遊戲最小下注金額為100"
        } else if (errorZeroAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "所有關數金額不能為0，但可勾選不啟動此設定"
        } else if (errorBetTypeCount > 0) {
          this.alertError = true
          this.repsMessage = "請處理必要欄位"
        }
        else {
          this.e1 = 3
        }
      }

    },
    step3Next() {
      // check bet type
      var errorBetTypeCount = checkBetTypeNotNull(this.bet_type_even)

      const even_amount_list = [];
      even_amount_list.push(this.txt_even_1)
      even_amount_list.push(this.txt_even_2)
      even_amount_list.push(this.txt_even_3)
      even_amount_list.push(this.txt_even_4)
      even_amount_list.push(this.txt_even_5)
      even_amount_list.push(this.txt_even_6)
      even_amount_list.push(this.txt_even_7)
      even_amount_list.push(this.txt_even_8)
      even_amount_list.push(this.txt_even_9)
      even_amount_list.push(this.txt_even_10)

      // check amount
      var errorAmountCount = checkAnyAmount(even_amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(even_amount_list)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(even_amount_list, this.txt_even_exeucte_num_of_pass)
      console.log(errorCount)

      if ((this.chk_even_disable === 1) || this.chk_even_disable === true) {
        this.e1 = 4
      } else {
        if (errorCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不符合"
        } else if (errorAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "不能輸入1~99，該遊戲最小下注金額為100"
        } else if (errorZeroAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "所有關數金額不能為0，但可勾選不啟動此設定"
        } else if (errorBetTypeCount > 0) {
          this.alertError = true
          this.repsMessage = "請處理必要欄位"
        }
        else {
          this.e1 = 4
        }
      }
    },
    step4Next() {
      // check bet type
      var errorBetTypeCount = checkBetTypeNotNull(this.bet_type_big)

      const big_amount_list = [];
      big_amount_list.push(this.txt_big_1)
      big_amount_list.push(this.txt_big_2)
      big_amount_list.push(this.txt_big_3)
      big_amount_list.push(this.txt_big_4)
      big_amount_list.push(this.txt_big_5)
      big_amount_list.push(this.txt_big_6)
      big_amount_list.push(this.txt_big_7)
      big_amount_list.push(this.txt_big_8)
      big_amount_list.push(this.txt_big_9)
      big_amount_list.push(this.txt_big_10)

      // check amount
      var errorAmountCount = checkAnyAmount(big_amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(big_amount_list)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(big_amount_list, this.txt_big_exeucte_num_of_pass)
      console.log(errorCount)

      if ((this.chk_big_disable === 1) || this.chk_big_disable === true) {
        this.e1 = 5
      } else {
        if (errorCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不符合"
        } else if (errorAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "不能輸入1~99，該遊戲最小下注金額為100"
        } else if (errorZeroAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "所有關數金額不能為0，但可勾選不啟動此設定"
        } else if (errorBetTypeCount > 0) {
          this.alertError = true
          this.repsMessage = "請處理必要欄位"
        }
        else {
          this.e1 = 5
        }
      }
    },
    step5Next() {
      // check bet type
      var errorBetTypeCount = checkBetTypeNotNull(this.bet_type_small)

      const small_amount_list = [];
      small_amount_list.push(this.txt_small_1)
      small_amount_list.push(this.txt_small_2)
      small_amount_list.push(this.txt_small_3)
      small_amount_list.push(this.txt_small_4)
      small_amount_list.push(this.txt_small_5)
      small_amount_list.push(this.txt_small_6)
      small_amount_list.push(this.txt_small_7)
      small_amount_list.push(this.txt_small_8)
      small_amount_list.push(this.txt_small_9)
      small_amount_list.push(this.txt_small_10)

      // check amount
      var errorAmountCount = checkAnyAmount(small_amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(small_amount_list)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(small_amount_list, this.txt_small_exeucte_num_of_pass)
      console.log(errorCount)

      if ((this.chk_small_disable === 1) || this.chk_small_disable === true) {
        this.e1 = 6
      } else {
        if (errorCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不符合"
        } else if (errorAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "不能輸入1~99，該遊戲最小下注金額為100"
        } else if (errorZeroAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "所有關數金額不能為0，但可勾選不啟動此設定"
        } else if (errorBetTypeCount > 0) {
          this.alertError = true
          this.repsMessage = "請處理必要欄位"
        }
        else {
          this.e1 = 6
        }
      }
    },
    step6Next() {
      // check bet type
      var errorBetTypeCount = checkBetTypeNotNull(this.bet_type_random)
      errorBetTypeCount = errorBetTypeCount + checkBetTypeNotNull(this.random_type)

      const random_amount_list = [];
      random_amount_list.push(this.txt_random_1)
      random_amount_list.push(this.txt_random_2)
      random_amount_list.push(this.txt_random_3)
      random_amount_list.push(this.txt_random_4)
      random_amount_list.push(this.txt_random_5)
      random_amount_list.push(this.txt_random_6)
      random_amount_list.push(this.txt_random_7)
      random_amount_list.push(this.txt_random_8)
      random_amount_list.push(this.txt_random_9)
      random_amount_list.push(this.txt_random_10)

      // check amount
      var errorAmountCount = checkAnyAmount(random_amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(random_amount_list)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(random_amount_list, this.txt_random_exeucte_num_of_pass)
      console.log(errorCount)

      if ((this.chk_random_disable === 1) || this.chk_random_disable === true) {
        this.e1 = 7
      } else {
        if (errorCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不符合"
        } else if (errorAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "不能輸入1~99，該遊戲最小下注金額為100"
        } else if (errorZeroAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "所有關數金額不能為0，但可勾選不啟動此設定"
        } else if (errorBetTypeCount > 0) {
          this.alertError = true
          this.repsMessage = "請處理必要欄位"
        }
        else {
          this.e1 = 7
        }
      }
    },
    selectBetting(e) {
      this.betting = e

      const requestData = {
        'betting': this.betting,
        'game_name': 'Bingo Ex',
      };

      gameData(requestData, this.userId)
        .then((response) => {
          console.log(response);
          this.repsData = response.data
          this.repsMessage = response.data.message;
          const errorCode = response.data.error_code;
          console.log(this.repsData);
          console.log(errorCode);

          if ((this.repsData.betting === 'null') || (typeof (this.repsData.betting) === "undefined") || (this.repsData.betting === "")) {
            this.betting = e
          } else {
            this.betting = this.repsData.betting;
          }

          this.account = this.repsData.account;
          this.password = this.repsData.password;
          this.bet_type = this.repsData.bet_type;
          this.bet_type_odd_even = this.repsData.bet_type_odd_even;
          this.bet_type_odd = this.repsData.bet_type_odd;
          this.bet_type_even = this.repsData.bet_type_even;
          this.bet_type_big_small = this.repsData.bet_type_big_small;
          this.bet_type_big = this.repsData.bet_type_big;
          this.bet_type_small = this.repsData.bet_type_small;
          this.bet_type_random = this.repsData.bet_type_random;
          this.random_type = this.repsData.random_type;
          this.chk_odd_disable = Number(this.repsData.chk_odd_disable ? this.repsData.chk_odd_disable : false);
          this.chk_even_disable = Number(this.repsData.chk_even_disable ? this.repsData.chk_even_disable : false);
          this.chk_big_disable = Number(this.repsData.chk_big_disable ? this.repsData.chk_big_disable : false);
          this.chk_small_disable = Number(this.repsData.chk_small_disable ? this.repsData.chk_small_disable : false);
          this.chk_random_disable = Number(this.repsData.chk_random_disable ? this.repsData.chk_random_disable : false);
          this.txt_odd_even_exeucte_num_of_pass = parseInt(this.repsData.txt_odd_even_exeucte_num_of_pass ? this.repsData.txt_odd_even_exeucte_num_of_pass : 1);
          this.txt_odd_exeucte_num_of_pass = parseInt(this.repsData.txt_odd_exeucte_num_of_pass ? this.repsData.txt_odd_exeucte_num_of_pass : 1);
          this.txt_even_exeucte_num_of_pass = parseInt(this.repsData.txt_even_exeucte_num_of_pass ? this.repsData.txt_even_exeucte_num_of_pass : 1);
          this.txt_odd_even_1 = parseInt(this.repsData.txt_odd_even_1 ? this.repsData.txt_odd_even_1 : 0);
          this.txt_odd_even_2 = parseInt(this.repsData.txt_odd_even_2 ? this.repsData.txt_odd_even_2 : 0);
          this.txt_odd_even_3 = parseInt(this.repsData.txt_odd_even_3 ? this.repsData.txt_odd_even_3 : 0);
          this.txt_odd_even_4 = parseInt(this.repsData.txt_odd_even_4 ? this.repsData.txt_odd_even_4 : 0);
          this.txt_odd_even_5 = parseInt(this.repsData.txt_odd_even_5 ? this.repsData.txt_odd_even_5 : 0);
          this.txt_odd_even_6 = parseInt(this.repsData.txt_odd_even_6 ? this.repsData.txt_odd_even_6 : 0);
          this.txt_odd_even_7 = parseInt(this.repsData.txt_odd_even_7 ? this.repsData.txt_odd_even_7 : 0);
          this.txt_odd_even_8 = parseInt(this.repsData.txt_odd_even_8 ? this.repsData.txt_odd_even_8 : 0);
          this.txt_odd_even_9 = parseInt(this.repsData.txt_odd_even_9 ? this.repsData.txt_odd_even_9 : 0);
          this.txt_odd_even_10 = parseInt(this.repsData.txt_odd_even_10 ? this.repsData.txt_odd_even_10 : 0);
          this.txt_odd_1 = parseInt(this.repsData.txt_odd_1 ? this.repsData.txt_odd_1 : 0);
          this.txt_odd_2 = parseInt(this.repsData.txt_odd_2 ? this.repsData.txt_odd_2 : 0);
          this.txt_odd_3 = parseInt(this.repsData.txt_odd_3 ? this.repsData.txt_odd_3 : 0);
          this.txt_odd_4 = parseInt(this.repsData.txt_odd_4 ? this.repsData.txt_odd_4 : 0);
          this.txt_odd_5 = parseInt(this.repsData.txt_odd_5 ? this.repsData.txt_odd_5 : 0);
          this.txt_odd_6 = parseInt(this.repsData.txt_odd_6 ? this.repsData.txt_odd_6 : 0);
          this.txt_odd_7 = parseInt(this.repsData.txt_odd_7 ? this.repsData.txt_odd_7 : 0);
          this.txt_odd_8 = parseInt(this.repsData.txt_odd_8 ? this.repsData.txt_odd_8 : 0);
          this.txt_odd_9 = parseInt(this.repsData.txt_odd_9 ? this.repsData.txt_odd_9 : 0);
          this.txt_odd_10 = parseInt(this.repsData.txt_odd_10 ? this.repsData.txt_odd_10 : 0);
          this.txt_even_1 = parseInt(this.repsData.txt_even_1 ? this.repsData.txt_even_1 : 0);
          this.txt_even_2 = parseInt(this.repsData.txt_even_2 ? this.repsData.txt_even_2 : 0);
          this.txt_even_3 = parseInt(this.repsData.txt_even_3 ? this.repsData.txt_even_3 : 0);
          this.txt_even_4 = parseInt(this.repsData.txt_even_4 ? this.repsData.txt_even_4 : 0);
          this.txt_even_5 = parseInt(this.repsData.txt_even_5 ? this.repsData.txt_even_5 : 0);
          this.txt_even_6 = parseInt(this.repsData.txt_even_6 ? this.repsData.txt_even_6 : 0);
          this.txt_even_7 = parseInt(this.repsData.txt_even_7 ? this.repsData.txt_even_7 : 0);
          this.txt_even_8 = parseInt(this.repsData.txt_even_8 ? this.repsData.txt_even_8 : 0);
          this.txt_even_9 = parseInt(this.repsData.txt_even_9 ? this.repsData.txt_even_9 : 0);
          this.txt_even_10 = parseInt(this.repsData.txt_even_10 ? this.repsData.txt_even_10 : 0);
          this.txt_big_small_exeucte_num_of_pass = parseInt(this.repsData.txt_big_small_exeucte_num_of_pass ? this.repsData.txt_big_small_exeucte_num_of_pass : 1);
          this.txt_big_exeucte_num_of_pass = parseInt(this.repsData.txt_big_exeucte_num_of_pass ? this.repsData.txt_big_exeucte_num_of_pass : 1);
          this.txt_small_exeucte_num_of_pass = parseInt(this.repsData.txt_small_exeucte_num_of_pass ? this.repsData.txt_small_exeucte_num_of_pass : 1);
          this.txt_big_small_1 = parseInt(this.repsData.txt_big_small_1 ? this.repsData.txt_big_small_1 : 0);
          this.txt_big_small_2 = parseInt(this.repsData.txt_big_small_2 ? this.repsData.txt_big_small_2 : 0);
          this.txt_big_small_3 = parseInt(this.repsData.txt_big_small_3 ? this.repsData.txt_big_small_3 : 0);
          this.txt_big_small_4 = parseInt(this.repsData.txt_big_small_4 ? this.repsData.txt_big_small_4 : 0);
          this.txt_big_small_5 = parseInt(this.repsData.txt_big_small_5 ? this.repsData.txt_big_small_5 : 0);
          this.txt_big_small_6 = parseInt(this.repsData.txt_big_small_6 ? this.repsData.txt_big_small_6 : 0);
          this.txt_big_small_7 = parseInt(this.repsData.txt_big_small_7 ? this.repsData.txt_big_small_7 : 0);
          this.txt_big_small_8 = parseInt(this.repsData.txt_big_small_8 ? this.repsData.txt_big_small_8 : 0);
          this.txt_big_small_9 = parseInt(this.repsData.txt_big_small_9 ? this.repsData.txt_big_small_9 : 0);
          this.txt_big_small_10 = parseInt(this.repsData.txt_big_small_10 ? this.repsData.txt_big_small_10 : 0);
          this.txt_big_1 = parseInt(this.repsData.txt_big_1 ? this.repsData.txt_big_1 : 0);
          this.txt_big_2 = parseInt(this.repsData.txt_big_2 ? this.repsData.txt_big_2 : 0);
          this.txt_big_3 = parseInt(this.repsData.txt_big_3 ? this.repsData.txt_big_3 : 0);
          this.txt_big_4 = parseInt(this.repsData.txt_big_4 ? this.repsData.txt_big_4 : 0);
          this.txt_big_5 = parseInt(this.repsData.txt_big_5 ? this.repsData.txt_big_5 : 0);
          this.txt_big_6 = parseInt(this.repsData.txt_big_6 ? this.repsData.txt_big_6 : 0);
          this.txt_big_7 = parseInt(this.repsData.txt_big_7 ? this.repsData.txt_big_7 : 0);
          this.txt_big_8 = parseInt(this.repsData.txt_big_8 ? this.repsData.txt_big_8 : 0);
          this.txt_big_9 = parseInt(this.repsData.txt_big_9 ? this.repsData.txt_big_9 : 0);
          this.txt_big_10 = parseInt(this.repsData.txt_big_10 ? this.repsData.txt_big_10 : 0);
          this.txt_small_1 = parseInt(this.repsData.txt_small_1 ? this.repsData.txt_small_1 : 0);
          this.txt_small_2 = parseInt(this.repsData.txt_small_2 ? this.repsData.txt_small_2 : 0);
          this.txt_small_3 = parseInt(this.repsData.txt_small_3 ? this.repsData.txt_small_3 : 0);
          this.txt_small_4 = parseInt(this.repsData.txt_small_4 ? this.repsData.txt_small_4 : 0);
          this.txt_small_5 = parseInt(this.repsData.txt_small_5 ? this.repsData.txt_small_5 : 0);
          this.txt_small_6 = parseInt(this.repsData.txt_small_6 ? this.repsData.txt_small_6 : 0);
          this.txt_small_7 = parseInt(this.repsData.txt_small_7 ? this.repsData.txt_small_7 : 0);
          this.txt_small_8 = parseInt(this.repsData.txt_small_8 ? this.repsData.txt_small_8 : 0);
          this.txt_small_9 = parseInt(this.repsData.txt_small_9 ? this.repsData.txt_small_9 : 0);
          this.txt_small_10 = parseInt(this.repsData.txt_small_10 ? this.repsData.txt_small_10 : 0);
          this.txt_random_exeucte_num_of_pass = parseInt(this.repsData.txt_random_exeucte_num_of_pass ? this.repsData.txt_random_exeucte_num_of_pass : 1);
          this.txt_random_1 = parseInt(this.repsData.txt_random_1 ? this.repsData.txt_random_1 : 0);
          this.txt_random_2 = parseInt(this.repsData.txt_random_2 ? this.repsData.txt_random_2 : 0);
          this.txt_random_3 = parseInt(this.repsData.txt_random_3 ? this.repsData.txt_random_3 : 0);
          this.txt_random_4 = parseInt(this.repsData.txt_random_4 ? this.repsData.txt_random_4 : 0);
          this.txt_random_5 = parseInt(this.repsData.txt_random_5 ? this.repsData.txt_random_5 : 0);
          this.txt_random_6 = parseInt(this.repsData.txt_random_6 ? this.repsData.txt_random_6 : 0);
          this.txt_random_7 = parseInt(this.repsData.txt_random_7 ? this.repsData.txt_random_7 : 0);
          this.txt_random_8 = parseInt(this.repsData.txt_random_8 ? this.repsData.txt_random_8 : 0);
          this.txt_random_9 = parseInt(this.repsData.txt_random_9 ? this.repsData.txt_random_9 : 0);
          this.txt_random_10 = parseInt(this.repsData.txt_random_10 ? this.repsData.txt_random_10 : 0);
          this.stop_win = parseInt(this.repsData.stop_win ? this.repsData.stop_win : 1000);
          this.stop_loss = parseInt(this.repsData.stop_loss ? this.repsData.stop_loss : -800);

          // default message
          this.bet_type_odd_ao_chu = this.bet_type_odd === "凹注" ? true : false
          this.bet_type_odd_ciang_gong = this.bet_type_odd === "強攻" ? true : false

          this.bet_type_even_ao_chu = this.bet_type_even === "凹注" ? true : false
          this.bet_type_even_ciang_gong = this.bet_type_even === "強攻" ? true : false

          this.bet_type_big_ao_chu = this.bet_type_big === "凹注" ? true : false
          this.bet_type_big_ciang_gong = this.bet_type_big === "強攻" ? true : false

          this.bet_type_small_ao_chu = this.bet_type_small === "凹注" ? true : false
          this.bet_type_small_ciang_gong = this.bet_type_small === "強攻" ? true : false

          this.bet_type_random_ao_chu = this.bet_type_random === "凹注" ? true : false
          this.bet_type_random_ciang_gong = this.bet_type_random === "強攻" ? true : false

        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);
        });

    },
    selectBetTypeOdd(value) {
      this.bet_type_odd = value
      this.bet_type_odd_ao_chu = value === "凹注"
      this.bet_type_odd_ciang_gong = value === "強攻"
    },
    selectBetTypeEven(value) {
      this.bet_type_even = value
      this.bet_type_even_ao_chu = value === "凹注"
      this.bet_type_even_ciang_gong = value === "強攻"
    },
    selectBetTypeBig(value) {
      this.bet_type_big = value
      this.bet_type_big_ao_chu = value === "凹注"
      this.bet_type_big_ciang_gong = value === "強攻"
    },
    selectBetTypeSmall(value) {
      this.bet_type_small = value
      this.bet_type_small_ao_chu = value === "凹注"
      this.bet_type_small_ciang_gong = value === "強攻"
    },
    selectBetTypeRandom(value) {
      this.bet_type_random = value
      this.bet_type_random_ao_chu = value === "凹注"
      this.bet_type_random_ciang_gong = value === "強攻"
    },
    selectRandomType(value) {
      this.random_type = value
    },
    openDisclaimer() {
      const order_tactic_list = [];
      order_tactic_list.push(this.chk_odd_disable)
      order_tactic_list.push(this.chk_even_disable)
      order_tactic_list.push(this.chk_big_disable)
      order_tactic_list.push(this.chk_small_disable)
      order_tactic_list.push(this.chk_random_disable)
      var errorOrderTactic = checkAnyOrderTactic(order_tactic_list)

      if (this.stop_win < 0 || this.stop_loss > 0) {
        this.alertError = true
        this.repsMessage = "請處理必要欄位"
      }
      else if (errorOrderTactic) {
        this.alertError = true
        this.repsMessage = "至少啟動設定一組投注策略"
      }
      else {
        window.scrollTo(0, -100);
        this.dialog = true
      }
    },
    startProcess() {
      this.dialog = false
      this.isShowProgress = true

      gameSettingData({
        betting: this.betting,
        game_name: 'Bingo EX',
        account: this.account,
        password: this.password,
        bet_type: this.bet_type,
        bet_type_odd_even: this.bet_type_odd_even,
        bet_type_big_small: this.bet_type_big_small,
        bet_type_odd: this.bet_type_odd ? this.bet_type_odd : "凹注",
        bet_type_even: this.bet_type_even ? this.bet_type_even : "凹注",
        bet_type_big: this.bet_type_big ? this.bet_type_big : "凹注",
        bet_type_small: this.bet_type_small ? this.bet_type_small : "凹注",
        bet_type_random: this.bet_type_random ? this.bet_type_random : "凹注",
        random_type: this.random_type ? this.random_type : "單、雙，二選一",
        chk_odd_disable: Number(this.chk_odd_disable),
        chk_even_disable: Number(this.chk_even_disable),
        chk_big_disable: Number(this.chk_big_disable),
        chk_small_disable: Number(this.chk_small_disable),
        chk_random_disable: Number(this.chk_random_disable),
        txt_odd_even_exeucte_num_of_pass: parseInt(this.txt_odd_even_exeucte_num_of_pass ? this.txt_odd_even_exeucte_num_of_pass : 1),
        txt_odd_exeucte_num_of_pass: parseInt(this.txt_odd_exeucte_num_of_pass ? this.txt_odd_exeucte_num_of_pass : 1),
        txt_even_exeucte_num_of_pass: parseInt(this.txt_even_exeucte_num_of_pass ? this.txt_even_exeucte_num_of_pass : 1),
        txt_odd_even_1: parseInt(this.txt_odd_even_1 ? this.txt_odd_even_1 : 0),
        txt_odd_even_2: parseInt(this.txt_odd_even_2 ? this.txt_odd_even_2 : 0),
        txt_odd_even_3: parseInt(this.txt_odd_even_3 ? this.txt_odd_even_3 : 0),
        txt_odd_even_4: parseInt(this.txt_odd_even_4 ? this.txt_odd_even_4 : 0),
        txt_odd_even_5: parseInt(this.txt_odd_even_5 ? this.txt_odd_even_5 : 0),
        txt_odd_even_6: parseInt(this.txt_odd_even_6 ? this.txt_odd_even_6 : 0),
        txt_odd_even_7: parseInt(this.txt_odd_even_7 ? this.txt_odd_even_7 : 0),
        txt_odd_even_8: parseInt(this.txt_odd_even_8 ? this.txt_odd_even_8 : 0),
        txt_odd_even_9: parseInt(this.txt_odd_even_9 ? this.txt_odd_even_9 : 0),
        txt_odd_even_10: parseInt(this.txt_odd_even_10 ? this.txt_odd_even_10 : 0),
        txt_odd_1: parseInt(this.txt_odd_1 ? this.txt_odd_1 : 0),
        txt_odd_2: parseInt(this.txt_odd_2 ? this.txt_odd_2 : 0),
        txt_odd_3: parseInt(this.txt_odd_3 ? this.txt_odd_3 : 0),
        txt_odd_4: parseInt(this.txt_odd_4 ? this.txt_odd_4 : 0),
        txt_odd_5: parseInt(this.txt_odd_5 ? this.txt_odd_5 : 0),
        txt_odd_6: parseInt(this.txt_odd_6 ? this.txt_odd_6 : 0),
        txt_odd_7: parseInt(this.txt_odd_7 ? this.txt_odd_7 : 0),
        txt_odd_8: parseInt(this.txt_odd_8 ? this.txt_odd_8 : 0),
        txt_odd_9: parseInt(this.txt_odd_9 ? this.txt_odd_9 : 0),
        txt_odd_10: parseInt(this.txt_odd_10 ? this.txt_odd_10 : 0),
        txt_even_1: parseInt(this.txt_even_1 ? this.txt_even_1 : 0),
        txt_even_2: parseInt(this.txt_even_2 ? this.txt_even_2 : 0),
        txt_even_3: parseInt(this.txt_even_3 ? this.txt_even_3 : 0),
        txt_even_4: parseInt(this.txt_even_4 ? this.txt_even_4 : 0),
        txt_even_5: parseInt(this.txt_even_5 ? this.txt_even_5 : 0),
        txt_even_6: parseInt(this.txt_even_6 ? this.txt_even_6 : 0),
        txt_even_7: parseInt(this.txt_even_7 ? this.txt_even_7 : 0),
        txt_even_8: parseInt(this.txt_even_8 ? this.txt_even_8 : 0),
        txt_even_9: parseInt(this.txt_even_9 ? this.txt_even_9 : 0),
        txt_even_10: parseInt(this.txt_even_10 ? this.txt_even_10 : 0),
        txt_big_small_exeucte_num_of_pass: parseInt(this.txt_big_small_exeucte_num_of_pass ? this.txt_big_small_exeucte_num_of_pass : 1),
        txt_big_exeucte_num_of_pass: parseInt(this.txt_big_exeucte_num_of_pass ? this.txt_big_exeucte_num_of_pass : 1),
        txt_small_exeucte_num_of_pass: parseInt(this.txt_small_exeucte_num_of_pass ? this.txt_small_exeucte_num_of_pass : 1),
        txt_big_small_1: parseInt(this.txt_big_small_1 ? this.txt_big_small_1 : 0),
        txt_big_small_2: parseInt(this.txt_big_small_2 ? this.txt_big_small_2 : 0),
        txt_big_small_3: parseInt(this.txt_big_small_3 ? this.txt_big_small_3 : 0),
        txt_big_small_4: parseInt(this.txt_big_small_4 ? this.txt_big_small_4 : 0),
        txt_big_small_5: parseInt(this.txt_big_small_5 ? this.txt_big_small_5 : 0),
        txt_big_small_6: parseInt(this.txt_big_small_6 ? this.txt_big_small_6 : 0),
        txt_big_small_7: parseInt(this.txt_big_small_7 ? this.txt_big_small_7 : 0),
        txt_big_small_8: parseInt(this.txt_big_small_8 ? this.txt_big_small_8 : 0),
        txt_big_small_9: parseInt(this.txt_big_small_9 ? this.txt_big_small_9 : 0),
        txt_big_small_10: parseInt(this.txt_big_small_10 ? this.txt_big_small_10 : 0),
        txt_big_1: parseInt(this.txt_big_1 ? this.txt_big_1 : 0),
        txt_big_2: parseInt(this.txt_big_2 ? this.txt_big_2 : 0),
        txt_big_3: parseInt(this.txt_big_3 ? this.txt_big_3 : 0),
        txt_big_4: parseInt(this.txt_big_4 ? this.txt_big_4 : 0),
        txt_big_5: parseInt(this.txt_big_5 ? this.txt_big_5 : 0),
        txt_big_6: parseInt(this.txt_big_6 ? this.txt_big_6 : 0),
        txt_big_7: parseInt(this.txt_big_7 ? this.txt_big_7 : 0),
        txt_big_8: parseInt(this.txt_big_8 ? this.txt_big_8 : 0),
        txt_big_9: parseInt(this.txt_big_9 ? this.txt_big_9 : 0),
        txt_big_10: parseInt(this.txt_big_10 ? this.txt_big_10 : 0),
        txt_small_1: parseInt(this.txt_small_1 ? this.txt_small_1 : 0),
        txt_small_2: parseInt(this.txt_small_2 ? this.txt_small_2 : 0),
        txt_small_3: parseInt(this.txt_small_3 ? this.txt_small_3 : 0),
        txt_small_4: parseInt(this.txt_small_4 ? this.txt_small_4 : 0),
        txt_small_5: parseInt(this.txt_small_5 ? this.txt_small_5 : 0),
        txt_small_6: parseInt(this.txt_small_6 ? this.txt_small_6 : 0),
        txt_small_7: parseInt(this.txt_small_7 ? this.txt_small_7 : 0),
        txt_small_8: parseInt(this.txt_small_8 ? this.txt_small_8 : 0),
        txt_small_9: parseInt(this.txt_small_9 ? this.txt_small_9 : 0),
        txt_small_10: parseInt(this.txt_small_10 ? this.txt_small_10 : 0),
        txt_random_exeucte_num_of_pass: parseInt(this.txt_random_exeucte_num_of_pass ? this.txt_random_exeucte_num_of_pass : 1),
        txt_random_1: parseInt(this.txt_random_1 ? this.txt_random_1 : 0),
        txt_random_2: parseInt(this.txt_random_2 ? this.txt_random_2 : 0),
        txt_random_3: parseInt(this.txt_random_3 ? this.txt_random_3 : 0),
        txt_random_4: parseInt(this.txt_random_4 ? this.txt_random_4 : 0),
        txt_random_5: parseInt(this.txt_random_5 ? this.txt_random_5 : 0),
        txt_random_6: parseInt(this.txt_random_6 ? this.txt_random_6 : 0),
        txt_random_7: parseInt(this.txt_random_7 ? this.txt_random_7 : 0),
        txt_random_8: parseInt(this.txt_random_8 ? this.txt_random_8 : 0),
        txt_random_9: parseInt(this.txt_random_9 ? this.txt_random_9 : 0),
        txt_random_10: parseInt(this.txt_random_10 ? this.txt_random_10 : 0),
        stop_win: parseInt(this.stop_win ? this.stop_win : 1000),
        stop_loss: parseInt(this.stop_loss ? this.stop_loss : -800),
      }, this.userId)
        .then((response) => {
          console.log(response);
          this.repsMessage = response.data.message;
          const errorCode = response.data.error_code;
          console.log(this.repsMessage);
          console.log(errorCode);

          this.isShowProgress = false;

          if (errorCode === "") {
            this.alertSuccess = true;
            setTimeout(() => {
              this.$router.push("/robot-agent-overview");
            }, this.timeout);

          } else {
            this.alertError = true;
          }
        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);

        });
    },
  }
};
</script>
<style scoped>
/* 使進度條在螢幕上居中，並且至於最頂層 */
.progress-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.disclaimer-overlay {
  position: fixed;
  top: 90%;
  left: 11%;
  z-index: 2000;
}
</style>