<template>
    <v-row class="align-center d-flex h-100 mt-md-n16">
        <v-col lg="4" md="7" class="mx-auto">
            <v-card class="card-shadow border-radius-xl py-1">
                <div class="
            card-padding
            text-center
            mt-n7
            bg-gradient-primary
            shadow-primary
            border-radius-lg
            mx-4
          ">
                    <h4 class="text-h4 font-weight-bolder text-white mb-2">
                        今天加入我們
                    </h4>
                    <p class="mb-1 text-white font-weight-light text-sm">
                        輸入您的電子郵件和密碼進行註冊
                    </p>
                </div>
                <div class="card-padding text-center">
                    <v-text-field label="經銷商編號" v-model="dealerNumber" color="#e91e63" outlined dense :disabled="true" hide-details class="font-size-input input-style mb-5"></v-text-field>
                    <v-text-field label="姓名" v-model="name" color="#e91e63" outlined dense hide-details class="font-size-input input-style mb-5"></v-text-field>
                    <v-menu v-model="menuBirthday" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateBirthday" label="生日" color="#e91e63" readonly outlined dense hide-details class="font-size-input input-style mb-5" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateBirthday" @input="menuBirthday = false" color="green lighten-1" locale="zh-tw"></v-date-picker>
                    </v-menu>
                    <v-text-field label="電話" v-model="phone" color="#e91e63" outlined dense hide-details class="font-size-input input-style mb-5"></v-text-field>
                    <!--<v-text-field label="地址" v-model="address" color="#e91e63" outlined dense hide-details class="font-size-input input-style mb-5"></v-text-field>-->
                    <v-text-field label="電子郵件" v-model="email" color="#e91e63" outlined dense hide-details class="font-size-input input-style mb-5"></v-text-field>
                    <v-text-field label="密碼" v-model="password" color="#e91e63" outlined dense hide-details type="password" class="font-size-input input-style mb-5"></v-text-field>
                    <v-checkbox v-model="checkbox" color="#141727" class="ma-0 checkbox-custom checkbox-thinner" hide-details>
                        <template v-slot:label>
                            <span class="text-body text-sm ls-0">我同意&nbsp;<a target="_blank" href="https://tripletwo.tw/%E8%B3%87%E8%A8%8A/%E8%B3%87%E8%A8%8A-%E6%9C%83%E5%93%A1%E6%AC%8A%E7%9B%8A/" class="text-dark font-weight-bolder text-decoration-none" @click.stop>條款和條件</a></span>
                        </template>
                    </v-checkbox>
                    <div class="text-center">
                        <v-progress-circular indeterminate :size="50" :width="5" color="blue" v-if="isShowProgress"></v-progress-circular>
                        <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                        <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="blue" content-class="text text-h4 mb-4" :timeout="timeout" absolute centered centered elevation="24">{{repsMessage}}</v-snackbar>
                    </div>
                    <v-btn elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            " color="#5e72e4" small @click="postSignUp">註冊</v-btn>
                    <p class="text-body font-weight-light mt-3 mb-0">
                        已有帳戶？
                        <a href="javascript:;" class="text-primary text-decoration-none font-weight-bolder" @click="goToLoginPage">登入</a>
                    </p>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Base64 = require("js-base64").Base64;

Vue.use(VueSweetalert2);

export default {
    name: "sign-up-basic",
    data() {
        return {
            checkbox: false,
            dealerNumber: "3",
            name: "",
            phone: "",
            address: "",
            email: "",
            password: "",
            menuBirthday: false,
            dateBirthday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            //dateBirthday: "",
            timeout: 1000,
            parentId: 3,
            alertSuccess: false,
            alertError: false,
            repsMessage: "",

        };
    },
    mounted() {
        console.log(this.$route.query.s)
        let s = this.$route.query.s
        console.log(typeof(s))
        console.log(this.dealerNumber)
        if (s === undefined) {
            //this.$swal("", "請聯繫 AI WIN 洽詢會員加入說明。", "error", window.location.href = 'https://tripletwo.tw/%e5%93%81%e7%89%8c%e5%8a%a0%e7%9b%9f/', "前往說明");
            this.$swal({
                title: '',
                text: '請聯繫 AI WIN 洽詢會員加入說明。',
                icon: 'error',
                confirmButtonText: '前往說明'
            }).then(
                function() {
                    window.location.href = 'https://tripletwo.tw/%e5%93%81%e7%89%8c%e5%8a%a0%e7%9b%9f/';
                });

            //setTimeout(() => {
            //    this.$router.push({ path: 'https://tripletwo.tw/%e5%93%81%e7%89%8c%e5%8a%a0%e7%9b%9f/' })
            //this.$router.push('https://tripletwo.tw/%e5%93%81%e7%89%8c%e5%8a%a0%e7%9b%9f/');
            //}, this.timeout)

        } else {
            let parentIdStr = s.split('u');
            let parentIdStrLength = parentIdStr.length;
            console.log(parentIdStrLength)
            console.log(parentIdStr[1])
            console.log(typeof(parentIdStr[1]))
            if (parentIdStr[1] === "") {
                //this.parentId = 3;
                //this.dealerNumber = "3";
                this.$swal({
                    title: '',
                    text: '請聯繫 AI WIN 洽詢會員加入說明。',
                    icon: 'error',
                    confirmButtonText: '前往說明'
                }).then(
                    function() {
                        window.location.href = 'https://tripletwo.tw/%e5%93%81%e7%89%8c%e5%8a%a0%e7%9b%9f/';
                    });

            } else {
                this.parentId = parseInt(parentIdStr[1]);
                this.dealerNumber = parentIdStr[1];
            }
            console.log(this.parentId)
            console.log(this.dealerNumber)
        }
    },
    methods: {
        postSignUp() {
            if (this.checkbox) {

                const resp = axios.post('/v1/signup', {
                        parent_id: this.parentId,
                        name: this.name,
                        password: this.password,
                        gender: "",
                        phone: this.phone,
                        email: this.email,
                        dealer_number: this.dealerNumber,
                        birthday: this.dateBirthday,
                        address: this.address,
                        bank_code: 0,
                        bank_account: 0,
                        identity: ""
                    }, this.config)
                    .then(response => {
                        console.log(response)
                        this.repsMessage = response.data.message
                        const errorCode = response.data.error_code
                        console.log(this.repsMessage)
                        console.log(errorCode)

                        if (errorCode === '') {
                            const resp = axios.post('/v1/login', {
                                    email: this.email,
                                    password: this.password,
                                }, this.config)
                                .then(response => {
                                    console.log(response)
                                    this.repsMessage = response.data.message
                                    const errorCode = response.data.error_code
                                    console.log(this.repsMessage)
                                    console.log(errorCode)

                                    if (errorCode === '') {
                                        this.alertSuccess = true
                                        this.auth = response.data.token;
                                        //this.$store.commit('setAuth', this.auth);
                                        Cookie.set('auth', this.auth);
                                        console.log(this.auth);

                                        var tokenParts = this.auth.split('.');
                                        var body = JSON.parse(atob(tokenParts[1]));
                                        console.log("token body");
                                        console.log(body);
                                        console.log(body.sub);

                                        this.userId = body.sub
                                        console.log(this.userId)

                                        setTimeout(() => {
                                            this.$router.push('/pages/pages/profile/overview');
                                        }, this.timeout)

                                    } else {
                                        this.alertError = true
                                    }
                                })
                                .catch(function(error) { // 请求失败处理
                                    console.log(error);
                                });



                        } else {
                            this.alertError = true
                            setTimeout(() => {
                                this.$router.go();
                            }, this.timeout)
                        }




                    })
                    .catch(function(error) { // 请求失败处理
                        console.log(error);
                    });
            } else {
                this.alertError = true
                this.repsMessage = "請勾選同意條款和條件"
            }
        },
        goToLoginPage() {
            setTimeout(() => {
                this.$router.push('/pages/authentication/signup/login');
            }, this.timeout)
        },
    },
};
</script>