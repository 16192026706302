<template>
  <v-container class="py-3 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/verify-phone.jpg')}) no-repeat center center fixed; background-size: cover; height: 100vh;`">

      <v-row class="text-center mx-8">
        <v-col cols="12" class="pt-0 pb-0 text-start">
          <h4 class="text-h4 mt-8 font-weight-bold" style="color: #5C6BC0;">
            輸入驗證碼
          </h4>
          <p class="font-weight-light mb-2" style="color: #5C6BC0;">
            已發送驗證碼至 {{ phone }}
          </p>
        </v-col>

        <v-col cols="12" class="pt-0 pb-1">
          <v-otp-input class="otp-input" v-model="phoneOtp" :length="4" type="number"></v-otp-input>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-btn color="#1A237E" class="
            font-weight-bolder
            btn-default
            rounded-pill
          " style="width:100%;font-size: 16px;" @click="comfirmPhoneVerifyCode">
            確定
          </v-btn>

        </v-col>
        <v-col cols="12" class="pt-0">
          <p v-if="!reSendTextFlag" class="
            font-weight-medium text-decoration-underline" style="color: #1A237E;font-size: 14px;">重新獲取（{{ countDown }}）
          </p>
          <p v-if="reSendTextFlag" class="
            font-weight-medium text-decoration-underline" style="color: #1A237E;font-size: 14px;"
            @click="reSendPhoneVerifyCode">重新獲取
          </p>
        </v-col>
      </v-row>
    </div>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-progress-circular v-if="isShowProgress" indeterminate color="#5E35B1" :size="70" :width="7"
          class="progress-overlay"></v-progress-circular>
      </v-row>
    </v-container>

  </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';
import { VOtpInput } from "vuetify/lib";
import { userDataUpdate, userData } from "@/apis/userService";

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": "application/json;",
  }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

export default {
  name: "SettingsVerifyPhone",
  components: {
    VOtpInput,
  },
  data() {
    return {
      userId: 1,
      switche: true,
      name: "",
      role: "",
      line_picture_link: "",
      phone: "",
      phoneOtp: "",
      defaultDateTime: new Date(),
      countDown: 300,
      reSendTextFlag: false,
      isShowProgress: false,

    };
  },
  created() {
    this.defaultDateTime = new Date()
    let reSendDateTime = this.defaultDateTime.setMinutes(this.defaultDateTime.getMinutes() + 5);
    this.countDownTimer(reSendDateTime)
  },
  mounted() {
    const token = Cookie.get('auth')

    console.log(token)
    console.log(typeof token)
    console.log((token === 'null') || (typeof (token) === "undefined"))
    if ((token === 'null') || (typeof (token) === "undefined")) {
      this.$router.push('/login');
    }

    const tokenParts = token.split('.')
    console.log(tokenParts)
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    userData(this.userId)
      .then(response => {
        this.repsData = response.data;
        console.log(this.repsData)

        this.name = this.repsData.name
        this.address = this.repsData.address
        this.gender = this.repsData.gender === 2 ? "女性" : "男性" //this.genders[this.repsData.gender - 1]
        this.phone = this.repsData.phone
        this.email = this.repsData.email
        this.email_pass_status = this.repsData.email_pass_status
        this.phone_pass_status = this.repsData.phone_pass_status
        this.role = this.repsData.role
        this.due_date = this.repsData.due_date
        this.birthday = this.repsData.birthday
        this.bank_code = this.repsData.bank_code
        this.bank_account = this.repsData.bank_account
        this.identity = this.repsData.identity
        this.line_notify_token = this.repsData.line_notify_token

      })
      .catch(error => { // 请求失败处理
        console.log(error);
      });
  },
  methods: {
    comfirmPhoneVerifyCode() {

      let data = {
        'user_id': this.userId,
        'active_code': this.phoneOtp,
      };

      const resp = axios.get('/v1/verify/phone', {
        params: data
      }, this.config)
        .then(response => {
          console.log(response)
          this.repsMessage = response.data.message
          const errorCode = response.data.error_code
          console.log(this.repsMessage)
          console.log(errorCode)

          if (errorCode === '') {
            this.isShowProgress = true
            setTimeout(() => {
              this.isShowProgress = false
              this.$router.push('/profile-settings');
            }, 1000)

          } else {
            this.$swal("", this.repsMessage, "error");
          }
        })
        .catch(function (error) { // 请求失败处理
          console.log(error);
        });
    },
    reSendPhoneVerifyCode() {
      let resp = axios.post('/v1/verify/phone/' + this.userId.toString(), config)
        .then(response => {
          this.repsData = response.data;
          console.log(this.repsData)
          const errorCode = response.data.error_code
          const message = response.data.message

          if (errorCode === '') {
            this.$swal({
              title: "",
              text: "重新傳送驗證碼至行動電話",
              icon: "success",
              confirmButtonText: "確認",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                setTimeout(() => {
                  //window.location.reload();
                  this.reSendTextFlag = false
                  this.defaultDateTime = new Date()
                  let reSendDateTime = this.defaultDateTime.setMinutes(this.defaultDateTime.getMinutes() + 5);
                  this.countDown = 300
                  this.countDownTimer(reSendDateTime)

                }, 100);
              }
            });

          } else {
            this.$swal("", message, "error");
          }

        })
        .catch(error => { // 请求失败处理
          console.log(error);
        });
    },
    countDownTimer(reSendDateTime) {
      var downtimer = setInterval(() => {
        const currentDateTime = new Date()
        const currentTime = currentDateTime.getTime()
        //console.log(currentTime)
        //console.log(reSendDateTime)

        const surplusTime = reSendDateTime - currentTime
        //console.log(surplusTime)

        this.countDown = Math.floor(surplusTime / 1000)
        //console.log(this.countDown)

        if (this.countDown <= 0) {
          this.reSendTextFlag = true
          clearInterval(downtimer)
        }

      }, 1000)


    },
  },
};
</script>
<style scoped>
::v-deep .otp-input .v-input input {
  font-size: 48px;
  color: #5C6BC0;
  max-height: 63.75px;
}

::v-deep .otp-input .v-text-field input {
  line-height: 63.75px;
}

/* 使進度條在螢幕上居中，並且至於最頂層 */
.progress-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>
