<template>
    <v-card class="card-shadow border-radius-xl mt-6" id="basic">
        <div class="px-6 py-6">
            <h5 class="text-h5 font-weight-bold text-typo">投注設定</h5>
        </div>
        <div class="px-6 pb-6 pt-0">
            <v-row class="mt-0">
                <v-col sm="4" cols="12">
                    <label class="text-sm text-body">娛樂城test</label>
                    <v-select v-model="play" :items="plays" label="多金" color="#e91e63" class="font-size-input input-style" single-line height="36">
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="account" color="#e91e63" label="帳號" placeholder="account" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="password" color="#e91e63" label="密碼" placeholder="password" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
        </div>
        <hr class="horizontal my-4" :class="sidebarTheme == 'dark' ? 'light' : 'dark'" />
        <div class="px-6 py-6">
            <h5 class="text-h5 font-weight-bold text-typo">下注</h5>
        </div>
        <div class="px-6 pb-6 pt-0">
            <v-row class="mt-0">
                <v-col sm="4" cols="12">
                    <label class="text-sm text-body">玩法</label>
                    <v-radio-group>
                        <v-radio label="隨機挑選" value="1"></v-radio>
                        <v-radio label="指定號碼" value="2"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>
        <hr class="horizontal my-4" :class="sidebarTheme == 'dark' ? 'light' : 'dark'" />
        <div class="px-6 py-6">
            <h5 class="text-h5 font-weight-bold text-typo">籌碼</h5>
        </div>
        <div class="px-6 pb-6 pt-0">
            <v-row class="mt-0">
                <v-col sm="4" cols="12">
                    <label class="text-sm text-body"></label>
                    <v-radio-group>
                        <v-radio label="凹注法" value="1"></v-radio>
                        <v-radio label="強攻法" value="2"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass1" color="#e91e63" label="第一關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass2" color="#e91e63" label="第二關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass3" color="#e91e63" label="第三關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass4" color="#e91e63" label="第四關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass5" color="#e91e63" label="第五關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass6" color="#e91e63" label="第六關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass7" color="#e91e63" label="第七關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="pass8" color="#e91e63" label="第八關" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
        </div>
        <hr class="horizontal my-4" :class="sidebarTheme == 'dark' ? 'light' : 'dark'" />
        <div class="px-6 py-6">
            <h5 class="text-h5 font-weight-bold text-typo">停利止損</h5>
        </div>
        <div class="px-6 pb-6 pt-0">
            <v-row>
                <v-col cols="4">
                    <v-text-field v-model="win_or_lose_amount" color="#e91e63" label="停利止損金額" placeholder="account" class="font-size-input input-style">
                    </v-text-field>
                </v-col>
            </v-row>
            <div class="d-sm-flex">
                <v-btn color="#cb0c9f" class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-4
            px-8
            ms-auto
            mt-sm-auto mt-4
          " small @click="executeOrder">
                    執行
                </v-btn>
            </div>
        </div>
    </v-card>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


Vue.use(VueSweetalert2);

export default {
    name: "basic-info",
    data() {
        return {
            name: "",
            address: "",
            genders: ["男性", "女性"],
            gender: "",
            plays: ["多金", "大老爺", "九州"],
            play: "",
            account: "",
            password: "",
            months: ["一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
            ],
            days: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            years: ["2021", "2020", "2019", "2018", "2017"],
            languages: ["English", "French", "Spanish"],
            skills: ["vuejs", "angular", "react"],
            menuStart: false,
        };
    },
    mounted() {
        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof token)
        console.log((token === 'null') || (typeof(token) === "undefined"))
        if ((token === 'null') || (typeof(token) === "undefined")) {
            this.$router.push('/pages/authentication/signup/login');
        }

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        axios.get('/v1/user/' + this.userId.toString(), this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.name = this.repsData.name
                this.address = this.repsData.address
                this.gender = this.genders[this.repsData.gender - 1]
                this.phone = this.repsData.phone
                this.email = this.repsData.email
                this.role = this.repsData.role
                this.due_date = this.repsData.due_date
                this.birthday = this.repsData.birthday
                this.bank_code = this.repsData.bank_code
                this.bank_account = this.repsData.bank_account
                this.identity = this.repsData.identity

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });



    },
    methods: {
        showUpdateSuccessAlert() {
            let genderValue = 1
            if (this.gender === "女性") {
                genderValue = 2
            }

            console.log(this.birthday)

            axios.put('/v1/member/' + this.userId.toString(), {
                    name: this.name,
                    gender: genderValue,
                    phone: this.phone,
                    email: this.email,
                    role: this.role,
                    due_date: this.due_date,
                    birthday: this.birthday,
                    address: this.address,
                    bank_code: this.bank_code,
                    bank_account: this.bank_account,
                    identity: this.identity,
                    password: ""
                }, this.config)
                .then(response => {
                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.code
                    console.log(this.repsMessage)
                    console.log(errorCode)
                    if (errorCode === 0) {
                        this.$swal("", "成功更新個人資訊。", "success");
                    }

                })
                .catch(function(error) { // 请求失败处理
                    console.log(error);
                });




        },
        showUpdatePwdSuccessAlert() {
            this.$swal("", "成功變更密碼。", "success");
        },
        executeOrder() {
            const resp = axios.post('/v1/agent/djg', {
                    game_type: 2,
                }, this.config)
                .then(response => {
                    console.log(response)
                    this.repsMessage = response.data.message
                    const errorCode = response.data.error_code
                    console.log(this.repsMessage)
                    console.log(errorCode)

                })
                .catch(function(error) { // 请求失败处理
                    console.log(error);
                });

        },
    },



};
</script>