<template>
    <div>
        <v-card class="border-radius-xl mt-6">
            <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="ordersOwn" :search="search" class="table border-radius-xl" :page.sync="page" hide-default-footer fixed-header @page-count="pageCount = $event" :items-per-page="itemsPerPage" mobile-breakpoint="0">
                    <template v-slot:top>
                        <v-toolbar flat height="80" class="border-radius-xl">
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field hide-details class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    " dense flat filled solo height="43" v-model="search" placeholder="搜尋">
                                        <template slot="prepend-inner">
                                            <v-icon color="#adb5bd" size="18px" class="material-icons-round mt-n2">search</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <!--<v-col lg="4" md="6" class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                                    <div class="d-flex align-center">
                                        <p class="mb-0 text-body text-xs ms-auto" v-if="switche == true">
                                            月
                                        </p>
                                        <p class="mb-0 text-body text-xs ms-auto" v-if="switche == false">
                                            年
                                        </p>
                                        <v-switch :ripple="false" class="mt-0 pt-0 ms-3 switch" v-model="switche" hide-details></v-switch>
                                    </div>
                                </v-col>-->
                            </v-row>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.item="{ item }">
                        <v-simple-table class="ml-auto d-flex align-center" mobile-breakpoint="1">
                            <tbody>
                                <tr v-for="(v, i) in item.item" :key="i" :value="v">
                                    <td>
                                        <v-img src="@/assets/img/default-cover.png" height="80" width="80">
                                            <v-img :src="item.coverUrl[i]" height="80" width="80">
                                            </v-img>
                                        </v-img>
                                    </td>
                                    <td class="align-center justify-center">
                                        {{v}}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </template>
                    <template v-slot:item.productCode="{ item }">
                        <v-simple-table class="ml-auto d-flex align-center" mobile-breakpoint="1">
                            <tbody>
                                <tr v-for="(v, i) in item.productCode" :key="i" :value="v">
                                    <td class="align-center justify-center">
                                        {{v}}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </template>
                    <template v-slot:item.number="{ item }">
                        <v-simple-table class="ml-auto d-flex align-center" mobile-breakpoint="1">
                            <tbody>
                                <tr v-for="(v, i) in item.number" :key="i" :value="v">
                                    <td class="align-center justify-center">
                                        {{v}}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </template>
                    <!--<template v-slot:item.item="{ item }">

  <v-row>

    <v-col cols="12"  v-for="(v, i) in item.item" :key="i" :value="v">
        <span >{{
                            v
                            }}</span>
    </v-col>
</v-row>


                      
                    </template>-->
                </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
                <v-row>
                    <v-col cols="6" lg="3" class="d-flex align-center">
                        <span class="text-body me-3 text-sm">每頁項目：</span>
                        <v-text-field hide-details type="number" outlined min="-1" max="15" background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage" @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page" class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              ">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="ml-auto d-flex justify-end">
                        <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#d81b60" v-model="page" :length="pageCount" circle></v-pagination>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
//import ordersOwn from './OrdersOwn';
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    name: "table-orders",
    data() {
        return {
            switche: true,
            page: 1,
            pageCount: 0,
            itemsPerPage: 20,
            page: 20,
            search: "",
            pageCount: 1,
            ordersOwn: [],
            search: "",
            images: ["https://i2.momoshop.com.tw/1680228676/goodsimg/0006/816/640/6816640_O_m.webp", "https://cs-a.ecimg.tw/items/DMAT4RA900BYRMV/000001_1679882641.jpg", "https://i2.momoshop.com.tw/1677149145/goodsimg/0010/531/700/10531700_R.webp"],
            headers: [{
                    text: "訂單編號",
                    align: "start",
                    sortable: false,
                    value: "orderId",
                    class: "text-secondary font-weight-bolder opacity-7 border-bottom",
                },
                {
                    text: "商品項目",
                    value: "item",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "品號",
                    value: "productCode",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "數量",
                    value: "number",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "金額",
                    value: "amount",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "積分",
                    value: "point",
                    class: "text-secondary font-weight-bolder opacity-7",
                },
                {
                    text: "交易時間",
                    value: "transactionTime",
                    class: "text-secondary font-weight-bolder opacity-7",
                },

            ],
        };
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
        pages() {
            return this.pagination.rowsPerPage ?
                Math.ceil(this.items.length / this.pagination.rowsPerPage) :
                0;
        },
    },
    mounted() {
        const token = Cookie.get('auth')
        const tokenParts = token.split('.')
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        const data = {
            'keyword': '',
            'offset': 0,
            'size': 20,
        };

        let resp = axios.get('/v1/order/' + this.userId.toString(), { params: data }, this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                console.log(this.repsData.total)


                this.ordersOwn = new Array(this.repsData.total)


                for (let i = 0; i < this.repsData.total; i++) {
                    this.ordersOwn[i] = new Object();
                    this.ordersOwn[i].orderId = this.repsData.list[i].number;

                    var amount = 0
                    var point = 0
                    this.ordersOwn[i].item = new Array(this.repsData.list[i].product.length)
                    this.ordersOwn[i].productCode = new Array(this.repsData.list[i].product.length)
                    this.ordersOwn[i].number = new Array(this.repsData.list[i].product.length)
                    this.ordersOwn[i].coverUrl = new Array(this.repsData.list[i].product.length)
                    for (let j = 0; j < this.repsData.list[i].product.length; j++) {
                        this.ordersOwn[i].item[j] = this.repsData.list[i].product[j].name
                        amount += this.repsData.list[i].product[j].price * this.repsData.list[i].product[j].qty
                        point += this.repsData.list[i].product[j].point * this.repsData.list[i].product[j].qty
                        this.ordersOwn[i].productCode[j] = this.repsData.list[i].product[j].sku
                        this.ordersOwn[i].number[j] = this.repsData.list[i].product[j].qty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.ordersOwn[i].coverUrl[j] = this.repsData.list[i].product[j].cover_url

                    }

                    this.ordersOwn[i].amount = "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.ordersOwn[i].point = point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    this.ordersOwn[i].transactionTime = this.repsData.list[i].transaction_time
                }


                console.log(this.ordersOwn)



            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });

    },
};
</script>