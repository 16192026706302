<template>
    <div>
        <v-row>
            <v-col>
                <v-container fluid class="px-6 py-6">
                    <v-row class="mt-8">
                        <v-col cols="auto">
                            <v-avatar width="74" height="74" class="shadow border-radius-lg">
                                <img src="@/assets/img/sketch.jpg" alt="Avatar" class="border-radius-lg" />
                            </v-avatar>
                        </v-col>
                        <v-col cols="auto" class="my-auto">
                            <div class="h-100">
                                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                                    {{year}}-{{month}}
                                </h5>
                                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                                    {{name}} &nbsp; <v-icon v-if="pass_status" color="#5FAF58" class="mt-n1 ps-1 pe-2">fa-check-circle</v-icon><span v-if="pass_status">合格</span>
                                    <v-icon v-if="!pass_status" color="#FF3333" class="mt-n1 ps-1 pe-2">fa-times-circle</v-icon><span v-if="!pass_status">尚未合格</span>
                                </h5>
                                <p class="mb-0 font-weight-light text-body text-sm">
                                    {{level}}階
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-8">
                        <v-col cols="12">
                            <datatable-search-bonus @update-row="updateRow"></datatable-search-bonus>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import DatatableSearchBonus from "./Widgets/DatatableSearchBonus.vue";
import Cookie from 'js-cookie';
import axios from 'axios';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    name: "datatables",
    components: {
        DatatableSearchBonus,
    },
    data: function() {
        return {
            userId: 0,
            name: "",
            level: 1,
            year: 1991,
            month: 1,
            pass_status: 0,
            recordBonusUserId: 1,

        }
    },
    mounted() {
        const date = new Date();
        this.month = date.getMonth() + 1;
        this.year = date.getFullYear();

        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof token)
        console.log((token === 'null') || (typeof(token) === "undefined"))
        if ((token === 'null') || (typeof(token) === "undefined")) {
            this.$router.push('/pages/authentication/signup/login');
        }

        const tokenParts = token.split('.')
        console.log(tokenParts)
        const body = JSON.parse(atob(tokenParts[1]))
        console.log(body)
        console.log(body.sub)

        this.userId = body.sub

        let s = this.$route.query.s
        console.log(typeof(s))
        if (s === undefined) {

            this.recordBonusUserId = body.sub


        } else {
            let parentIdStr = s.split('u');
            let parentIdStrLength = parentIdStr.length;
            console.log(parentIdStrLength)
            console.log(parentIdStr[1])
            this.recordBonusUserId = parentIdStr[1]
        }



        axios.get('/v1/user/' + this.recordBonusUserId.toString(), this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.name = this.repsData.name

            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });

        axios.get('/v1/user/' + this.recordBonusUserId.toString() + '/record-bonus', this.config)
            .then(response => {
                this.repsData = response.data;
                console.log(this.repsData)

                this.year = this.repsData.record_bonus.year
                this.month = this.repsData.record_bonus.month
                this.pass_status = this.repsData.record_bonus.pass_status
                this.level = this.repsData.record_bonus.level


            })
            .catch(error => { // 请求失败处理
                console.log(error);
            });

    },
    methods: {
        updateRow(row) {
            let yearMonth = row.item.yearMonth
            let yearMonthAry = yearMonth.split('-');

            this.year = parseInt(yearMonthAry[0])
            this.month = parseInt(yearMonthAry[1])

            let passStatus = row.item.passStatus
            if (passStatus === "合格"){
                this.pass_status = 1
            } else {
                this.pass_status = 0
            }

            this.level = row.item.level
        },

        
    },
};
</script>